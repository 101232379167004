import { createSelector } from 'redux-bundler';
import { AVAILABLE_LANGUAGES } from './i18n';

export default {
  name: 'appPathname',

  selectBasePathname: createSelector(
    'selectPathname',
    pathname => parsePathname(pathname).basePathname,
  ),

  selectPathnameLanguagePrefix: createSelector(
    'selectPathname',
    pathname => parsePathname(pathname).languagePrefix,
  ),
};

function parsePathname(pathname) {
  const tokens = pathname.split('/').slice(1);

  if (AVAILABLE_LANGUAGES.indexOf(tokens[0]) >= 0) {
    return { languagePrefix: tokens[0], basePathname: `/${tokens.slice(1).join('/')}` };
  }

  return { languagePrefix: null, basePathname: pathname };
}
