import { createSelector } from 'redux-bundler';
import { useConnect } from 'redux-bundler-hook';

import { localizedField } from './helpers/mayerApiLocalization';

export const AVAILABLE_LANGUAGES = ['de', 'en'];
export const DEFAULT_LANGUAGE = 'de';

const BLANK_TRANSLATIONS = {};

export default {
  name: 'i18n',

  selectCurrentLanguage: createSelector(
    'selectPathnameLanguagePrefix',
    prefix => {
      if (!prefix) {
        return DEFAULT_LANGUAGE;
      }

      if (AVAILABLE_LANGUAGES.indexOf(prefix) < 0) {
        return DEFAULT_LANGUAGE;
      }

      return prefix;
    },
  ),

  selectLanguageSwitcherLinks: createSelector(
    'selectBasePathname',
    makeLanguageSwitcherLinks,
  ),

  selectTranslations: createSelector(
    'selectMayerGuiTranslations',
    'selectCurrentLanguage',
    (data, language) => makeTranslations(data, language),
  ),

  selectTranslationsReady: createSelector(
    'selectTranslations',
    translations => translations !== BLANK_TRANSLATIONS,
  ),
};

export function useTranslate() {
  const { translations } = useConnect('selectTranslations');
  return { t: labelKey => translations[labelKey] || labelKey };
}

function makeLanguageSwitcherLinks(pathname) {
  return AVAILABLE_LANGUAGES.reduce(
    (links, lang) => ({
      ...links,
      [lang]: lang === DEFAULT_LANGUAGE ? pathname : `/${lang}${pathname}`,
    }),
    {},
  );
}

function makeTranslations(mayerGuiTranslations, language) {
  if (!mayerGuiTranslations || !language) {
    return BLANK_TRANSLATIONS;
  }

  return mayerGuiTranslations.reduce((translations, item) => {
    translations[item.token] = localizedField(item, null, language);
    return translations;
  }, {});
}
