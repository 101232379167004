import React, { useEffect } from 'react';
import { useConnect } from 'redux-bundler-hook';
import { useLinks } from '../../bundles/router';

import ResourceStatusBoundary from '../../controls/ResourceStatusBoundary';
import Spinner from '../../controls/Spinner';

export default function LoadOrderByCode() {
  return (
    <ResourceStatusBoundary bundleKey="allProducts" bundleKeyPredicate={isNotEmpty}>
      <ContentComponent />
    </ResourceStatusBoundary>
  );
}

function ContentComponent() {
  const { routeParams, doLoadOrdersByCode, doUpdateUrl } = useConnect(
    'selectRouteParams',
    'doLoadOrdersByCode',
    'doUpdateUrl',
  );
  const { makeLink, makeOrderProductConstructorLink } = useLinks();

  useEffect(() => {
    doLoadOrder();

    async function doLoadOrder() {
      if (!routeParams.loadOrderCode) {
        return;
      }

      const orderIds = await doLoadOrdersByCode(routeParams.loadOrderCode);
      if (orderIds.length) {
        doUpdateUrl(makeOrderProductConstructorLink(orderIds[0]));
      } else {
        doUpdateUrl(makeLink('/'));
      }
    }
  }, [makeOrderProductConstructorLink, makeLink, doLoadOrdersByCode, doUpdateUrl, routeParams.loadOrderCode]);

  return <Spinner type="block" />;
}

function isNotEmpty(data) {
  return Boolean(data && data.length);
}
