import { createSelector } from 'redux-bundler';
import { asyncResources } from 'redux-bundler-async-resources';
import { makeProductOptions } from './helpers/dataMappers';

const NAME = 'currentOrder';

const ACTION_CHANGED = `${NAME}.CHANGED`;

export default {
  name: NAME,

  reducer: (state = { rawData: null, language: null, data: null }, action) => {
    if (action.type === ACTION_CHANGED) {
      return action.payload;
    }
    return state;
  },

  selectCurrentOrderRaw: state => state[NAME],

  selectCurrentOrderId: createSelector(
    'selectQueryObject',
    'selectPendingOrders',
    ({ orderId }, orders) => {
      if (orderId && orders.some(order => order.id === orderId)) {
        return orderId;
      }
      return null;
    },
  ),

  selectCurrentOrderProductId: createSelector(
    'selectCurrentOrder',
    order => {
      if (!order) {
        return null;
      }
      return order.product.id;
    },
  ),

  selectCurrentOrder: createSelector(
    'selectCurrentOrderId',
    'selectPendingOrders',
    (orderId, orders) => {
      if (!orderId) {
        return null;
      }
      return orders.find(order => order.id === orderId);
    },
  ),

  selectCurrentOrderProductOptionsReady: createSelector(
    'selectCurrentOrderRaw',
    ({ data }) => Boolean(data),
  ),

  selectCurrentOrderProductOptions: createSelector(
    'selectCurrentOrderRaw',
    ({ data }) => data,
  ),

  reactShouldUpdateCurrentOrderProductOptions: createSelector(
    'selectCurrentOrderRaw',
    'selectCurrentOrderProductId',
    'selectItemsOfMayerMachineOptions',
    'selectCurrentLanguage',
    ({ rawData: prevRawData, language: prevLanguage }, productId, optionsItems, language) => {
      const rawData = productId && asyncResources.getItemData(optionsItems[productId]);
      if (rawData !== prevRawData || language !== prevLanguage) {
        return {
          type: ACTION_CHANGED,
          payload: {
            rawData,
            language,
            data: makeProductOptions(rawData, language),
          },
        };
      }
    },
  ),

  reactShouldFetchCurrentOrderOptions: createSelector(
    'selectCurrentOrderProductId',
    'selectItemsOfMayerMachineOptions',
    (productId, optionsItems) => {
      if (productId && asyncResources.itemIsPendingForFetch(optionsItems[productId])) {
        return { actionCreator: 'doFetchItemOfMayerMachineOptions', args: [productId] };
      }
    },
  ),
};
