import axios from 'axios';

const HOST = process.env.REACT_APP_API_URL;

const CONTENT_TYPE = 'application/x-www-form-urlencoded';
const INTERNAL_API_FAILURE = 'Invalid API call';
const NO_DATA_FOUND = 'No data found';

export default class MayerApiFetcher {
  constructor() {
    this.axios = axios.create({
      baseURL: HOST,
      'Content-Type': CONTENT_TYPE,
    });
  }

  async fetchBusinessAreas() {
    return await this.fetchData('GET_BUSINESS_AREAS');
  }

  async fetchMainMachines() {
    return await this.fetchData('GET_MAIN_MACHINES', { BusinessID: 0 });
  }

  async fetchMachineOptions({ articleNr }) {
    return await this.fetchData('GET_MACHINE_OPTIONS', { ArticleNr: articleNr });
  }

  async fetchTechnicalDetails({ articleNr }) {
    return await this.fetchData('GET_TECHNICAL_DETAILS', { ArticleNr: articleNr });
  }

  async fetchGuiTranslations() {
    return await this.fetchData('GET_GUI_TRANSLATIONS');
  }

  async loadOrderByCode(code) {
    return await this.fetchData('LOAD_CONFIGURATION', { filename_qr: code });
  }

  async fetchProductTypes() {
    return await this.fetchData('GET_CATEGORIES');
  }

  async loadPackagesForMachine({ machine }) {
    return await this.fetchData('LOAD_PACKAGE', { machine: machine });
  }

  async storeOrder(
    { firstName, lastName, email, phone, company, zip, country, sonderanforderungen, kundentyp, kuerzel, kuerzelEmail },
    language,
    orders,
  ) {
    return await this.fetchData('SAVE_CONFIGURATION', {
      customername: firstName + ' ' + lastName,
      customerFirstName: firstName,
      customerLastName: lastName,
      customeremail: email,
      customerphone: phone,
      customerincname: company,
      customerzip: zip,
      customercountry: country,
      sonderanforderungen: sonderanforderungen,
      customerType: kundentyp,
      haendlerKuerzel: kuerzel,
      haendlerKuerzelEmail: kuerzelEmail,
      saveConfig: true,
      Language: language.toUpperCase(),
      configurations: orders.map(({ product, articleIds }) => ({
        main: product.id,
        mo: articleIds.map(id => ({
          machineoption: id,
          amount: 1,
        })),
      })),
    });
  }

  async fetchData(action, params = {}) {
    const response = await this.doPost({ DATA: JSON.stringify({ FUNCT_KEY: action, ...params }) });

    const responseData = response.data.data;
    if (responseData === INTERNAL_API_FAILURE) throw INTERNAL_API_FAILURE;
    if (responseData === NO_DATA_FOUND) return [];

    return responseData;
  }

  async doPost(data) {
    return await this.axios.post('', paramsToFormData(data));
  }
}

function paramsToFormData(params) {
  const formData = new FormData();

  for (const key in params) {
    formData.append(key, params[key]);
  }

  return formData;
}
