import MayerApiFetcher from '../helpers/MayerApiFetcher';

const NAME = 'mayerApi';

export default {
  name: NAME,
  getExtraArgs: () => ({
    mayerApiFetcher: new MayerApiFetcher(),
  }),
};
