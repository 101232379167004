import { createSelector } from 'redux-bundler'
import queryString from 'query-string'

export default {
  name: 'queryParams',

  selectRouteQueryString: createSelector(
    'selectUrlObject',
    url => url.search
  ),

  selectRouteQueryParams: createSelector(
    'selectRouteQueryString',
    search => queryString.parse(search)
  ),
}
