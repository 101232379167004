import React from 'react';

import styles from './PageContent.module.scss';

export default function PageContent({ children }) {
  return<> <div className={styles.container}>{children}</div>

  </>
  ;
}
