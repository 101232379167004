import { createSelector } from 'redux-bundler';
import { asyncResources } from 'redux-bundler-async-resources';

import { makeProductOptions } from './helpers/dataMappers';

const NAME = 'currentProductOptions';
const ACTION_CHANGED = `${NAME}.CHANGED`;

export default {
  name: NAME,

  reducer: (state = { data: null, rawData: null, language: null }, action) => {
    if (action.type === ACTION_CHANGED) {
      return {
        ...state,
        ...action.payload,
      };
    }
    return state;
  },

  selectCurrentProductOptions: state => state[NAME].data,
  selectCurrentProductOptionsRawData: state => state[NAME].rawData,
  selectCurrentProductOptionsLanguage: state => state[NAME].language,
  selectCurrentProductOptionsReady: createSelector(
    'selectCurrentProductOptions',
    options => Boolean(options),
  ),

  selectCurrentItemOfMayerMachineOptions: createSelector(
    'selectItemsOfMayerMachineOptions',
    'selectCurrentProductId',
    (machineOptions, productId) => machineOptions[productId || null] || null,
  ),

  reactShouldFetchMachineOptions: createSelector(
    'selectCurrentProductId',
    'selectCurrentItemOfMayerMachineOptions',
    (productId, currentMachineOptions) => {
      if (productId && asyncResources.itemIsPendingForFetch(currentMachineOptions)) {
        return { actionCreator: 'doFetchItemOfMayerMachineOptions', args: [productId] };
      }
    },
  ),

  reactCurrentProductOptionsChanged: createSelector(
    'selectCurrentItemOfMayerMachineOptions',
    'selectCurrentProductOptionsRawData',
    'selectCurrentLanguage',
    'selectCurrentProductOptionsLanguage',
    (nextMainMachines, prevRawData, nextLang, prevLang) => {
      const nextRawData = asyncResources.getItemData(nextMainMachines) || null;
      if (prevRawData !== nextRawData || nextLang !== prevLang) {
        return {
          type: ACTION_CHANGED,
          payload: {
            data: makeProductOptions(nextRawData, nextLang),
            rawData: nextRawData,
            language: nextLang,
          },
        };
      }
    },
  ),
};
