import React, { Component } from 'react'
import InputRange from 'react-input-range'
import './RangeSlider.css'

class RangeSlider extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = { value: this.props.value};
    }

    changeValue = (value) =>{
        let state = this.setState(value);
        if(this.props.onChange !== undefined && typeof this.props.onChange == 'function'){
            this.props.onChange(value);
        }
        return state;
    }

    render() {
        return (
            <InputRange
                id={this.props.id}
                minValue={this.props.minValue}
                maxValue={this.props.maxValue}
                step={this.props.step}
                value={this.state.value}
                formatLabel={value => `${value}` + this.props.formatLabel}
                onChange={value => this.changeValue({value})} />
        )
    }
}
/*onChange={value=>this.setState({value})}*/
export default RangeSlider;
