import React from 'react';
import Loader from 'react-loader-spinner';

import styles from './Spinner.module.scss';

export default function Spinner({ type }) {
  let spinnerColor = '#036735';
  let spinnerHeight = null;
  let spinnerClassName = null;

  if (type === 'sm') {
    spinnerColor = '#CCE3C6';
    spinnerHeight = 40;
  }

  if (type === 'page') {
    spinnerClassName = styles.fullPage;
  }

  if (type === 'block') {
    spinnerClassName = styles.block
  }

  return (
    <div className={spinnerClassName}>
      <Loader type="Bars" color={spinnerColor} height={spinnerHeight} />
    </div>
  );
}
