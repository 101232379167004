import React from 'react';
import cn from 'classnames';
import { useConnect } from 'redux-bundler-hook';
import useResizeAware from 'react-resize-aware';
import posed, { PoseGroup } from 'react-pose';

import styles from './ProductConstructorDisplay.module.scss';

export const DISPLAY_ASPECT = 720 / 1280;

const Layer = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 250 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 150 },
  },
});

export default function ProductConstructorDisplay() {
  const {
    configuratorBaseImage: baseImage,
    configuratorDisplayImages: layers,
    configuratorPreloaderStatuses: preloaderStatuses,
  } = useConnect(
    'selectConfiguratorBaseImage',
    'selectConfiguratorDisplayImages',
    'selectConfiguratorPreloaderStatuses',
  );

  const [ResizeListener, sizes] = useResizeAware();

  const wrapperHeight = (sizes && sizes.width * DISPLAY_ASPECT) || 0;

  const baseImagePreloaded = preloaderStatuses[baseImage];
  const preloaded = layers.every(({ imageUrl }) => preloaderStatuses[imageUrl]);

  return (
    <div className={cn(styles.wrapper, { [styles.loadingWrapper]: !preloaded })} style={{ height: wrapperHeight }}>
      {ResizeListener}

      <PoseGroup>
        {[{ key: 'base', imageUrl: baseImage }, ...layers].map(({ key, imageUrl }) => {
          if (!baseImagePreloaded || !preloaderStatuses[imageUrl]) {
            return null;
          }
          return <Layer key={key} className={styles.layer} style={{ backgroundImage: `url(${imageUrl})` }} />;
        })}
      </PoseGroup>
    </div>
  );
}
