import React from 'react';

import Hover from '../../controls/Hover';
import ProductPopover from '../../components/ProductPopover';


import styles from './Product.module.scss';

export default function Product({product})
{

  return (

    <div className={styles.product+" singleProduct"} id={product.name} minpotsize={product.minPotSize} maxpotsize={product.maxPotSize} catid={product.productTypeId} performance={product.performance}>
        <br />
      <Hover onHover={<ProductPopover product={product} />}>

              <figure className={styles.product__figure}>
        <img src={product.imageUrl} alt={product.name} />
              </figure>
          <div className={styles.product_name}>{product.name}</div>
      </Hover>
    </div>
  );
}
