import { createSelector } from 'redux-bundler';
import { asyncResources } from 'redux-bundler-async-resources';
import _sortBy from 'lodash/sortBy';

import { localizedField } from './helpers/mayerApiLocalization';

const NAME = 'currentTechnicalDetails';
const ACTION_CHANGED = `${NAME}.CHANGED`;

const BLANK_DATA = {
  technicalDetailsTable: [],
  downloads: [],
};

const DOWLOADS_FILE_TYPE = '1';
const BILDER_IMAGES_FILE_TYPE = '2';

export default {
  name: NAME,

  reducer: (state = { data: BLANK_DATA, rawData: BLANK_DATA, language: null }, action) => {
    if (action.type === ACTION_CHANGED) {
      return {
        ...state,
        ...action.payload,
      };
    }
    return state;
  },

  selectCurrentTechnicalDetails: state => state[NAME].data,
  selectCurrentTechnicalDetailsRawData: state => state[NAME].rawData,
  selectCurrentTechnicalDetailsLanguage: state => state[NAME].language,
  selectCurrentTechnicalDetailsTable: createSelector(
    'selectCurrentTechnicalDetails',
    technicalDetails => technicalDetails.technicalDetailsTable,
  ),
  selectCurrentTechnicalDetailsDownloads: createSelector(
    'selectCurrentTechnicalDetails',
    technicalDetails => technicalDetails.downloads,
  ),
  selectCurrentTechnicalDetailsFileDownloads: createSelector(
    'selectCurrentTechnicalDetailsDownloads',
    downloads => downloads.filter(entity => entity.DownloadFileType === DOWLOADS_FILE_TYPE),
  ),
  selectCurrentTechnicalDetailsImageDownloads: createSelector(
    'selectCurrentTechnicalDetailsDownloads',
    downloads => downloads.filter(entity => entity.DownloadFileType === BILDER_IMAGES_FILE_TYPE),
  ),

  selectCurrentTechnicalDetailsReady: createSelector(
    'selectCurrentItemOfMayerTechnicalDetails',
    technicalDetails => asyncResources.itemIsPresent(technicalDetails),
  ),

  selectCurrentItemOfMayerTechnicalDetails: createSelector(
    'selectItemsOfMayerTechnicalDetails',
    'selectCurrentProductId',
    (technicalDetails, productId) => technicalDetails[productId || null] || null,
  ),

  reactShouldFetchTechnicalDetails: createSelector(
    'selectCurrentProductId',
    'selectCurrentItemOfMayerTechnicalDetails',
    (productId, currentTechnicalDetails) => {
      if (productId && asyncResources.itemIsPendingForFetch(currentTechnicalDetails)) {
        return { actionCreator: 'doFetchItemOfMayerTechnicalDetails', args: [productId] };
      }
    },
  ),

  reactCurrentTechnicalDetailsChanged: createSelector(
    'selectCurrentItemOfMayerTechnicalDetails',
    'selectCurrentTechnicalDetailsRawData',
    'selectCurrentLanguage',
    'selectCurrentTechnicalDetailsLanguage',
    (nextMainMachines, prevRawData, nextLang, prevLang) => {
      const nextRawData = (nextMainMachines && nextMainMachines.data) || BLANK_DATA;
      if (prevRawData !== nextRawData || nextLang !== prevLang) {
        return {
          type: ACTION_CHANGED,
          payload: {
            data: {
              technicalDetailsTable: makeCurrentTechnicalDetailsTable(nextRawData.technical_details_table, nextLang),
              downloads: nextRawData.downloads,
            },
            rawData: nextRawData,
            language: nextLang,
          },
        };
      }
    },
  ),
};

function makeCurrentTechnicalDetailsTable(rawData, lang) {
  /*
      articleID: "1010-0000-00-00"
      parameter_DE: "Topfgrösse (T16)"
      parameter_EN: "Pot size (T16)"
      sort_order: "1"
      value_DE: "5 bis 22 CM Ø"
      value_EN: "5 to 22 CM Ø"
     */
  return _sortBy(rawData, 'sort_order').reduce(
    (acc, cur) => ({
      ...acc,
      [localizedField(cur, 'parameter', lang)]: localizedField(cur, 'value', lang),
    }),
    {},
  );
}
