import React, { useState } from 'react';
import { useConnect } from 'redux-bundler-hook';

import styles from './Order.module.scss';
import { useTranslate } from '../../bundles/i18n';
import { PageContentHeader, PageContentHeaderButton, PageContentHeaderTitle } from '../../layouts/PageContent';

import DefaultStepper from '../../components/Stepper';



export default function Order() {
  const { t } = useTranslate();

  const { ordersSubmitting, doSubmitPendingOrders } = useConnect(
    'selectOrdersSubmitting',
    'doSubmitPendingOrders',
    'doUpdateUrl',
  );

  const [isDealerOrder, setIsDealerOrder] = useState(false);
  const [isDealerValid, setIsDealerValid] = useState(false);
  const [inputHaendlerKuerzel, setHaendlerKuerzel] = useState("");

  const handleSubmit = e => {
    e.preventDefault();

    doSubmitPendingOrders({
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      company: e.target.company.value,
      zip: e.target.zip.value,
      place: e.target.place.value,
      country: e.target.country.value,
      sonderanforderungen: e.target.sonderanforderungen.value,
      kuerzel: (e.target.kuerzel && e.target.kuerzel.value) || '',
      kuerzelEmail: (e.target.kuerzelEmail && e.target.kuerzelEmail.value) || '',
    });
  };


  const validKuerzel = [
    '7932',
    '1496',
    '2783',
    '2811',
    '2011',
    '8577',
    '1881',
    '1695',
    '5455',
    '9195',
    '1393',
    '1298',
    '8313',
    '9055',
    '3602',
    '4609',
    '9462',
    '2400',
    '1854',
    '1437',
    '1999',
    '1802',
    '8578',
    '2588',
    '3138',
    '4866',
    '7047',
    '4135',
    '7862',
    '6545',
    '3665',
    '7373',
    '3180',
    '4487',
    '4740',
    '9344',
    '2868',
    '6467',
  ];

  return (
    <form onSubmit={handleSubmit} className={styles.wrapper} style={{ paddingBottom: '40px' }}>
      <DefaultStepper step={2}/>

      <div className={styles.titleContent}>
        <h1>{t('LNG_REQUEST_OFFER_PAGE_TITLE')}</h1>
        <p>{t('LNG_REQUEST_OFFER_PAGE_SUB_TITLE')}</p>
      </div>

      <div className={styles.formContent}>
        <div className={styles.kundenTyp} style={{ marginBottom: isDealerOrder ? 0 : 20 }}>
          <div>
            <input
              type="radio"
              id={'endkunde'}
              name="kundentyp"
              value="endkunde"
              defaultChecked={true}
              onChange={() => setIsDealerOrder(false)}
            />

            <label htmlFor="endkunde" dangerouslySetInnerHTML={{ __html: t('LNG_REQUEST_OFFER_PAGE_FORM_ENDKUNDE') }}/>

          </div>
          <div>
            <input
              id={'haendler'}
              type="radio"
              name="kundentyp"
              value="haendler"
              onChange={() => setIsDealerOrder(true)}
            />

            <label htmlFor="haendler" dangerouslySetInnerHTML={{ __html: t('LNG_REQUEST_OFFER_PAGE_FORM_HAENDLER') }}/>
          </div>
        </div>
        <div >
        {!isDealerOrder && <p className={styles.titelCust}>{`${t('LNG_REQUEST_OFFER_PAGE_FORM_KUNDENDATEN')}`}</p>}

        </div>
        <div >
        {isDealerOrder && <p className={styles.titelData}>{`${t('LNG_REQUEST_OFFER_PAGE_FORM_HAENDLERDATEN')}`}</p>}

        </div>

        {isDealerOrder && <input
          id="kuerzel"
          name="kuerzel"
          type="text"
          onChange={(event) => {
            console.log(validKuerzel.includes(event.target.value));
            setHaendlerKuerzel(event.target.value);
            if (validKuerzel.includes(event.target.value))
            {
              setIsDealerValid(true)
            } else
            {
              setIsDealerValid(false)
            }
            console.log(isDealerValid);
            console.log({event:event.target.value});
          }}
          placeholder={`${t('LNG_REQUEST_OFFER_PAGE_FORM_KUERZEL')}`}
          className={styles.kuerzel}
        />}

          {isDealerOrder && <input
            id="kuerzelEmail"
            name="kuerzelEmail"
            type="email"
            placeholder={`${t('LNG_REQUEST_OFFER_PAGE_FORM_KUERZELEMAIL')}`}
            className={styles.kuerzel}
          />}
          {!isDealerValid && isDealerOrder && inputHaendlerKuerzel != "" && <p
            className={styles.kuerzelError}
          >
            {t('LNG_REQUEST_OFFER_PAGE_FORM_INVALID_KUERZEL')}
          </p>}
        <div >
        {isDealerOrder && <p className={styles.titelCust}>{`${t('LNG_REQUEST_OFFER_PAGE_FORM_KUNDENDATEN')}`}</p>}

        </div>
        <input
          required
          name="firstName"
          autoFocus
          type="text"
          placeholder={`${t('LNG_REQUEST_OFFER_PAGE_FORM_FIRSTNAME')} *`}
          disabled={ordersSubmitting}
        />

        <input
          required
          name="lastName"
          type="text"
          placeholder={`${t('LNG_REQUEST_OFFER_PAGE_FORM_LASTNAME')} *`}
          disabled={ordersSubmitting}
        />

        <input
          required
          name="company"
          type="text"
          placeholder={`${t('LNG_REQUEST_OFFER_PAGE_FORM_COMPANY')} *`}
          disabled={ordersSubmitting}
        />

        <input
          required
          name="email"
          type="email"
          placeholder={`${t('LNG_REQUEST_OFFER_PAGE_FORM_EMAIL')} *`}
          disabled={ordersSubmitting}
        />

        <input required name="zip" type="text" placeholder={`${t('LNG_REQUEST_OFFER_PAGE_FORM_ZIP')} *`}
               disabled={ordersSubmitting}/>

               <input required name="place" type="text" placeholder={`${t('LNG_REQUEST_OFFER_PAGE_FORM_PLACE')} *`}
                      disabled={ordersSubmitting}/>
        <input
          required
          name="phone"
          type="text"
          placeholder={`${t('LNG_REQUEST_OFFER_PAGE_FORM_PHONE')} *`}
          disabled={ordersSubmitting}
        />

        <input
          required
          name="country"
          type="country"
          placeholder={`${t('LNG_REQUEST_OFFER_PAGE_FORM_COUNTRY')} *`}
        />

        <input
          className={styles.full_width}
          name="sonderanforderungen"
          type="text"
          placeholder={`${t('LNG_REQUEST_OFFER_PAGE_FORM_SONDERANFORDERUNGEN')}`}
        />

        <label required className={styles.checkbox}>
          <div dangerouslySetInnerHTML={{ __html: t('LNG_REQUEST_OFFER_PAGE_FORM_POLICY') }}/>
          <input required type="checkbox" id="policyAccepted" name="policyAccepted"/>
          <span/>
        </label>
      </div>
      <PageContentHeader condense>
        <PageContentHeaderTitle smaller>{t('LNG_CONFIGURATION_FINISHED_TITLE')}</PageContentHeaderTitle>
        <PageContentHeaderButton isSubmit disabled={ordersSubmitting}>
          {t('LNG_REQUEST_OFFER_BUTTON_CONTINUE')}
        </PageContentHeaderButton>
      </PageContentHeader>
    </form>
  );
}
