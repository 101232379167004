import { localizedField } from './mayerApiLocalization';

export function makeProductOptions(rawMayerMachineOptions, language) {
  //console.log({rawMayerMachineOptions:rawMayerMachineOptions});
  if (!rawMayerMachineOptions) {
    return null;
  }
  return rawMayerMachineOptions
    .filter(option => option['Language'] === language.toUpperCase())
    .map(option => ({
      id: option['ArticleNr'],
      name: option['ArticleName'],
      price: option['ArticlePrice'],

      business: option['Business'],
      performance: option['ArticlePerformance'],
      PotSize: option['PotSize'],

      order: Number(option['SortOrderPos']),
      categoryOrder: Number(option['CategorySortOrder']),
      constructorImageUrl: option['ImageStandard'],
      constructorHighlightedImageUrl: option['ImageHighlight'],
      categoryId: option['IDCategory'],
      categoryName: localizedField(option, 'CategoryName', language),
      categoryImageUrl: option['ArticleImageURL'],
      categoryDescription: option['ArticleDetailText'],
      groupCategoryItems: Boolean(Number(option['GroupCategoryItems'])),
      subCategoryId: option['IDSubCategory'],
      subCategoryName: localizedField(option, 'Subcategory', language),
      forbidden: option['IsForbidden'],
      mandatory: option['IsMandatory'],
      required: Boolean(Number(option['ItemSelectRequired'])),
      hidesOptionIds: parseIdList(option['excluded_articles']),
      multipleOrder: option['multipleOrder'],

      info:
        (Boolean(option['ArticleName'] && option['ArticleDescription']) && {
          title: option['ArticleName'],
          description: option['ArticleDescription'],
          imageUrl: option['ImageHighlight'],
        }) ||
        null,

      __raw: option,
    }));
}

function parseIdList(input) {
  if (!input) {
    return null;
  }

  if (Array.isArray(input)) {
    return input;
  }

  return input.split(/[^\w\d-]+/i);
}
