import React from 'react';
import { useConnect } from 'redux-bundler-hook';
import posed, { PoseGroup } from 'react-pose';
import { useTranslate } from '../bundles/i18n';

import { PageContentHeader, PageContentHeaderTab, PageContentHeaderTitle } from '../layouts/PageContent';
import ResourceStatusBoundary from '../controls/ResourceStatusBoundary';
import { useLinks } from '../bundles/router';

import iconImage from '../assets/img/configuration-icon.svg';
import loadOrderImage from '../assets/img/load-order.svg';

import styles from './ContentHeader.module.scss';

const FocusedCategory = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    transition: { type: 'tween', duration: 900, ease: 'circOut' },
  },
  exit: {
    x: -300,
    opacity: 0,
    transition: { duration: 0 },
  },
});

const FocusedTitle = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    transition: { type: 'tween', duration: 900, ease: 'circOut' },
  },
  exit: {
    x: 300,
    opacity: 0,
    transition: { duration: 0 },
  },
});

export default function ContentHeader() {
  const {
    basePathname,
    productCategories,
    currentProductCategory,
    contentHeaderArgs,
    currentProduct,
    pendingOrders,
    currentOrder,
    configuratorReady,
  } = useConnect(
    'selectBasePathname',
    'selectProductCategories',
    'selectCurrentProductCategory',
    'selectContentHeaderArgs',
    'selectCurrentProduct',
    'selectPendingOrders',
    'selectCurrentOrder',
    'selectConfiguratorReady',
  );

  const { t } = useTranslate();

  const { makeCategoryLink, makeOrderProductConstructorLink, makeLoadOrderLink } = useLinks();

  if (contentHeaderArgs && contentHeaderArgs.hide) {
    return null;
  }

  const focusProduct = Boolean(
    configuratorReady &&
      currentProduct &&
      currentProductCategory &&
      contentHeaderArgs &&
      contentHeaderArgs.focusProduct,
  );

  const isLoadOrderPage = basePathname === '/load';

  return (
    <PageContentHeader>
      <ResourceStatusBoundary spinnerType="sm" resource="mayerBusinessAreas">
        <PoseGroup>
          {focusProduct && [
            <FocusedCategory key={`focused-tab-${currentProduct.id}`} className={styles.headerItem}>
              <PageContentHeaderTab
                outlined
                route={makeCategoryLink(currentProductCategory.id)}
                title={currentProductCategory.title}
                imageUrl={currentProductCategory.imageUrl}
              />
            </FocusedCategory>,
            <FocusedTitle key={`focused-title-${currentProduct.id}`} className={styles.headerItem}>
              <PageContentHeaderTitle className={styles.focusedProductTitle}>
                {currentProduct.name}
              </PageContentHeaderTitle>
            </FocusedTitle>,
          ]}
        </PoseGroup>

        {!focusProduct && [
          ...productCategories.map(category => (
            <PageContentHeaderTab
              key={`category-${category.id}`}
              route={makeCategoryLink(category.id)}
              title={category.title}
              imageUrl={category.imageUrl}
              active={!isLoadOrderPage && category === currentProductCategory}
            />
          )),
          ...pendingOrders.map(order => (
            <PageContentHeaderTab
              key={`order-${order.id}`}
              className={styles.pendingOrderTab}
              route={makeOrderProductConstructorLink(order.id)}
              title={order.product.name}
              imageUrl={iconImage}
              active={order === currentOrder}
            />
          )),
          <PageContentHeaderTab
            key="load-order"
            className={styles.loadOrderTab}
            route={makeLoadOrderLink()}
            title={t('LNG_MAINPAGE_LOAD_ORDER')}
            imageUrl={loadOrderImage}
            active={isLoadOrderPage}
          />,
        ]}
      </ResourceStatusBoundary>
    </PageContentHeader>
  );
}
