import React from 'react';
import cn from 'classnames';

import { useLinks } from '../../bundles/router';

import styles from './PrimaryButton.module.scss';

export default function PrimaryButton({ children, route, isSubmit, onClick, className, outlined, disabled , out}) {
  const { navHelper } = useLinks();

  const classNames = cn(styles.button, className, { [styles.outlined]: outlined, [styles.disabled]: disabled , [styles.out]: out });

  if (isSubmit || onClick || !route) {
    return (
      <button type={(isSubmit && 'submit') || 'button'} className={classNames} onClick={onClick} disabled={disabled}>
        {children}
      </button>
    );
  }

  return (
    <a href={route} className={classNames} onClick={navHelper}>
      {children}
    </a>
  );
}
