import { createAsyncResourceBundle } from 'redux-bundler-async-resources';
import { createSelector } from 'redux-bundler';

export default {
  ...createAsyncResourceBundle({
    name: 'mayerGuiTranslations',
    staleAfter: Infinity,
    expireAfter: Infinity,
    getPromise: ({ mayerApiFetcher }) => mayerApiFetcher.fetchGuiTranslations(),
  }),

  reactShouldFetchMayerGuiTranslations: createSelector('selectMayerGuiTranslationsIsPendingForFetch', shouldFetch => {
    if (shouldFetch) {
      return { actionCreator: 'doFetchMayerGuiTranslations' };
    }
  }),
};
