import { createRouteBundle, createSelector } from 'redux-bundler';
import { getNavHelper } from 'internal-nav-helper';
import { useConnect } from 'redux-bundler-hook';
import LoadOrder from '../containers/LoadOrder/LoadOrder';
import LoadOrderByCode from '../containers/LoadOrderByCode';

import ProductConstructor from '../containers/ProductConstructor';
import ProductList from '../containers/ProductList';
import ProductConstructorSubmit from '../containers/ProductConstructorSubmit';
import Order from '../containers/Order';

import NotFound from '../containers/NotFound';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from './i18n';
import OrderSuccess from '../containers/OrderSuccess';

const routeBundle = createRouteBundle({
  ...makeAppRoutes(),
  '*': { component: NotFound },
});

routeBundle.selectContentHeaderArgs = createSelector('selectRoute', ({ contentHeaderArgs }) => contentHeaderArgs);

routeBundle.selectContentArgs = createSelector('selectRoute', ({ contentArgs }) => contentArgs);

export default routeBundle;

export function useLinks() {
  const { doUpdateUrl, currentLanguage } = useConnect('doUpdateUrl', 'selectCurrentLanguage');

  const makeLink = baseRoute => makeLinkWithCurrentLanguage(baseRoute, currentLanguage);
  return {
    navHelper: getNavHelper(doUpdateUrl),

    samePageNavHelper: getNavHelper(url => doUpdateUrl(url, { maintainScrollPosition: true })),

    makeLink,

    makeCategoryLink: categoryId => makeLink(`/${categoryId}`),

    makeProductConstructorLink: product => makeLink(`/${product.categoryId}/${product.id}/builder`),

    makeOrderProductConstructorLink: orderId => makeLink(`/builder?orderId=${orderId}`),

    makeProductConstructorSubmitLink: () => makeLink('/submit'),

    makeProductDetailsLink: product => makeLink(`/${product.categoryId}/${product.id}`),

    makeOrderLink: () => makeLink('/order'),

    makeLoadOrderLink: code => (code ? makeLink(`/load/${code}`) : makeLink('/load')),
  };
}

export function makeLinkWithCurrentLanguage(baseRoute, currentLanguage) {
  if (currentLanguage === DEFAULT_LANGUAGE) {
    return baseRoute;
  }
  return `/${currentLanguage}${baseRoute}`;
}

function makeAppRoutes() {
  return [...AVAILABLE_LANGUAGES, null].reduce(
    (routes, lang) => ({
      ...routes,
      ...makeRoutes(lang),
    }),
    {},
  );
}

function makeRoutes(lang) {
  const prefix = (lang && `/${lang}`) || '';
    return {
    [`${prefix}/`]: {
      component: ProductList,
    },
    [`${prefix}/load`]: {
      component: LoadOrder,
    },
    [`${prefix}/load/:loadOrderCode`]: {
      component: LoadOrderByCode,
      contentHeaderArgs: { hide: true },
    },
    [`${prefix}/order`]: {
      component: Order,
      contentHeaderArgs: { hide: true },
    },
    [`${prefix}/order/success`]: {
      component: OrderSuccess,
      contentHeaderArgs: { hide: true },
    },
    [`${prefix}/submit`]: {
      component: ProductConstructorSubmit,
      contentHeaderArgs: { hide: true },
      contentArgs: { fluid: true },
    },
    [`${prefix}/builder`]: {
      component: ProductConstructor,
      contentHeaderArgs: { focusProduct: true },
    },
    [`${prefix}/:categoryId`]: {
      component: ProductList,
    },
    [`${prefix}/:categoryId/:productId`]: {
      component: ProductList,
    },
    [`${prefix}/:categoryId/:productId/builder`]: {
      component: ProductConstructor,
      contentHeaderArgs: { focusProduct: true },
    },
  };
}
