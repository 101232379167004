import React from 'react';
import cn from 'classnames';

import styles from './PageContentHeaderTitle.module.scss';

export default function PageContentHeaderTitle({ children, className, smaller }) {
  return (

    <h2 className={cn(styles.title, className, { [styles.smaller]: smaller })}>{children}</h2>);
}
