import { localizedField } from './helpers/mayerApiLocalization';
import { createSelector } from 'redux-bundler';

const EMPTY = [];

export const ALL_PRODUCT_CATEGORY = '0';

export default {
  name: 'productCategories',

  selectProductCategories: createSelector('selectMayerBusinessAreas', 'selectCurrentLanguage', (data, language) => {
    if (!data) {
      return EMPTY;
    }
    return data.map(({ BusinessID, ImageURL, ...rest }) => ({
      id: BusinessID,
      imageUrl: ImageURL,
      title: localizedField(rest, 'BusinessName', language),
    }));
  }),
};
