import { createAsyncResourceBundle } from 'redux-bundler-async-resources';
import { createSelector } from 'redux-bundler';
import { ONE_DAY, ONE_HOUR } from './constants';

export default {
  ...createAsyncResourceBundle({
    name: 'mayerMainMachines',

    getPromise: ({ mayerApiFetcher }) => mayerApiFetcher.fetchMainMachines(),

    retryAfter: 5 * 1000,
    staleAfter: ONE_HOUR,
    expireAfter: ONE_DAY,
  }),

  reactShouldFetchMayerMainMachines: createSelector('selectMayerMainMachinesIsPendingForFetch', isPending => {
    if (isPending) {
      return { actionCreator: 'doFetchMayerMainMachines' };
    }
  }),
};
