import { createSelector } from 'redux-bundler';
import { asyncResources } from 'redux-bundler-async-resources';
import uuid from 'uuid';

import { makeProductOptions } from './helpers/dataMappers';

import { makeLinkWithCurrentLanguage } from './router';

const NAME = 'productOrderManager';

const ACTION_STORE_ORDER = `${NAME}.STORE_ORDER`;
const ACTION_UPDATE_ORDER = `${NAME}.UPDATE_ORDER`;
const ACTION_ORDER_SUBMIT_STARTED = `${NAME}.ORDER_SUBMIT_STARTED`;
const ACTION_ORDER_SUBMIT_FINISHED = `${NAME}.ORDER_SUBMIT_FINISHED`;
const ACTION_ORDER_SUBMIT_FAILED = `${NAME}.ORDER_SUBMIT_FAILED`;
const ACTION_ORDER_SELECT_ID = `${NAME}.ORDER_SELECT_ID`;
const ACTION_ORDER_REMOVE_ID = `${NAME}.ORDER_REMOVE_ID`;

export default {
  name: NAME,

  persistActions: [ACTION_STORE_ORDER, ACTION_ORDER_SUBMIT_FINISHED, ACTION_ORDER_REMOVE_ID],

  reducer: (state = { orders: [], submitting: false, orderCode: null }, action) => {
    if (action.type === ACTION_STORE_ORDER) {
      return {
        ...state,
        orders: [...state.orders, action.payload],
      };
    }

    if (action.type === ACTION_ORDER_SUBMIT_STARTED) {
      return {
        ...state,
        submitting: true,
      };
    }

    if (action.type === ACTION_ORDER_SUBMIT_FINISHED) {
      return {
        orders: [],
        orderCode: {
          code: action.payload.QRC,
          imageUrl: action.payload.QRC,
          configCode: action.payload.configCode,
          url: action.payload.url,
          continueConfiguration: action.payload.continueConfiguration,
        },
        submitting: false,
      };
    }

    if (action.type === ACTION_ORDER_SUBMIT_FAILED) {
      return {
        ...state,
        submitting: false,
      };
    }

    if (action.type === ACTION_ORDER_SELECT_ID) {
        const { id } = action.payload;
      return {
        ...state,
        selectedOrderID: id,
      };
    }

    // console.log(ACTION_ORDER_REMOVE_ID)
    if (action.type === ACTION_ORDER_REMOVE_ID) {
      const { id } = action.payload;
      const newOrders = state.orders.filter((orderItem) => {
        return orderItem.id !== id;
      });
      return {
        ...state,
        orders: newOrders
      }
    }

    if (action.type === ACTION_UPDATE_ORDER) {
      const { id, articleIds } = action.payload;
      const orderIndex = state.orders.findIndex(order => order.id === id);
      if (orderIndex >= 0) {
        return {
          ...state,
          orders: [
            ...state.orders.slice(0, orderIndex),
            { ...state.orders[orderIndex], articleIds },
            ...state.orders.slice(orderIndex + 1),
          ],
        };
      }
    }

    return state;
  },

  selectProductOrderManagerRaw: state => state[NAME],

  selectOrdersSubmitting: createSelector('selectProductOrderManagerRaw', ({ submitting }) => submitting),

  selectPendingOrders: createSelector('selectProductOrderManagerRaw', ({ orders }) => {
    return orders;
  }),

  selectPendingOrdersOptions: createSelector(
    'selectPendingOrders',
    'selectItemsOfMayerMachineOptions',
    'selectCurrentLanguage',
    (orders, optionsItems, lang) =>
      orders.reduce(
        (hash, order) => ({
          ...hash,
          [order.id]: asyncResources.itemIsPresent(optionsItems[order.product.id])
            ? makeProductOptions(asyncResources.getItemData(optionsItems[order.product.id]), lang)
            : null,
        }),
        {},
      ),
  ),

  selectOrderManagerOrderCode: createSelector('selectProductOrderManagerRaw', ({ orderCode }) => orderCode),

  doLoadOrdersByCode: code => async ({ mayerApiFetcher, dispatch, store }) => {
    const ordersData = await mayerApiFetcher.loadOrderByCode(code);

    const orders = ordersData.map(orderItem => ({
      productId: orderItem.main,
      articleIds: orderItem.mo.map(option => option.machineoption),
    }));

    const allProducts = store.selectAllProducts();

    return orders
      .map(({ productId, articleIds }) => ({
        id: uuid.v4(),
        product: allProducts.find(p => p.id === productId),
        articleIds,
      }))
      .filter(order => Boolean(order.product))
      .map(order => {
        dispatch({
          type: ACTION_STORE_ORDER,
          payload: order,
        });

        return order.id;
      });
  },

  doStoreOrder: (orderId, product, articleIds) => ({
    type: ACTION_STORE_ORDER,
    payload: { id: orderId, product, articleIds },
  }),

  doRemoveItem: (articleId) => ({
    type: ACTION_ORDER_REMOVE_ID,
    payload: { id: articleId },
  }),

  doUpdateOrder: (orderId, articleIds) => ({
    type: ACTION_UPDATE_ORDER,
    payload: { id: orderId, articleIds },
  }),

  doSelectOrderID: (orderId,) => ({
    type: ACTION_ORDER_SELECT_ID,
    payload: { id: orderId },
  }),

  doSubmitPendingOrders: customer => async ({ dispatch, store, mayerApiFetcher }) => {
    try {
      dispatch({ type: ACTION_ORDER_SUBMIT_STARTED });
      const response = await mayerApiFetcher.storeOrder(
        customer,
        store.selectCurrentLanguage(),
        store.selectPendingOrders(),
      );
      console.log({response:response});
      dispatch({ type: ACTION_ORDER_SUBMIT_FINISHED, payload: response });
      store.doUpdateUrl(makeLinkWithCurrentLanguage('/order/success', store.selectCurrentLanguage()));
    } catch (error) {
      console.error('error submitting order: ', error);
      dispatch({ type: ACTION_ORDER_SUBMIT_FAILED, payload: error });
    }
  },

  reachShouldFetchPendingOrderOptions: createSelector(
    'selectPendingOrders',
    'selectItemsOfMayerMachineOptions',
    (orders, optionsItems) => {
      for (const order of orders) {
        if (asyncResources.itemIsPendingForFetch(optionsItems[order.product.id])) {
          return { actionCreator: 'doFetchItemOfMayerMachineOptions', args: [order.product.id] };
        }
      }
    },
  ),
};
