import React, { Component } from 'react'
import './checkbox.css'
import RangeSlider from "../RangeSlider/RangeSlider";

class Checkbox extends Component{
    constructor(props,context){
        super(props, context)
        this.state = {isChecked: props.checked}
    }

    toggleChange = () => {
        this.setState({isChecked:!this.state.isChecked});
        if(this.props.callback !== undefined && typeof this.props.callback == 'function'){
            this.props.callback(this.props.value,this.props.valIdx);
        }
    }

    render(){
        return(
          <label className={"cbContainer"} style={{fontSize:"12px",paddingTop:"3px"}}><b>{this.props.label}</b>
                <input type={"checkbox"} id={this.props.id} checked={this.state.isChecked} value={this.props.value} onChange={this.toggleChange}/>
                <span className={"cbCheckmark"}></span>
            </label>
        )
    }
}

export default Checkbox;