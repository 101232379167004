import React from 'react';

import styles from './stepper.module.scss';

import Stepper from 'react-stepper-horizontal';

import { useTranslate } from '../../bundles/i18n';
import {makeLinkWithCurrentLanguage} from "../../bundles/router";

export default function DefaultStepper(step) {
  const { t } = useTranslate();

  return (
    <div className={styles.wrapper}>
      <Stepper
        activeStep={parseInt(step.step)}
        steps={[
          { title: t('LNG_BUILDER_KONFIGURATION') , href: "/" },
          { title: t('LNG_BUILDER_REVIEW')  , href: "javascript:history.back()" },
          { title: t('LNG_BUILDER_CONTACTS') , href: "javascript:history.back()" },
          { title: t('LNG_BUILDER_PRICES') },
        ]}
        activeColor="#036735"
        completeColor="rgb(224, 224, 224)"
        barStyle="dashed"
        completeBarColor="#036735"
      />
    </div>
  );
}
