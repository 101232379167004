import React from 'react';

import styles from './Hover.module.scss';

export default function Hover({ onHover, children })  {

  return (
    <div className={styles.hover}>
      <div className={styles.hover__no_hover}>{children}</div>
      <div className={styles.hover__hover}>{onHover}</div>
    </div>
  );
}
