import { createSelector } from 'redux-bundler';
import _sortBy from 'lodash/sortBy';
import _isEqual from 'lodash/isEqual';

const NAME = 'productConfigurator';

const ACTION_RESET = `${NAME}.RESET`;
const ACTION_LOAD_ORDER = `${NAME}.DID_LOAD_ORDER`;
const ACTION_SELECTED_CATEGORY = `${NAME}.SELECTED_CATEGORY`;
const ACTION_SELECTED_SUBCATEGORY = `${NAME}.SELECTED_SUBCATEGORY`;
const ACTION_SUBMITTED_CATEGORY = `${NAME}.SUBMITTED_CATEGORY`;
const ACTION_SELECTION_CHANGED = `${NAME}.SELECTION_CHANGED`;
const ACTION_OPTION_HIGHLIGHT_TOGGLED = `${NAME}.OPTION_HIGHLIGHT_TOGGLED`;
const ACTION_CHANGE_PACKAGE_CONFIG = `${NAME}.CHANGE_PACKAGE_CONFIG`;

const ACTION_POPUP_INFO_CHANGED = `${NAME}.POPUP_INFO_CHANGED`;
const ACTION_SHOW_PACKAGES_MODAL_CHANGED = `${NAME}.SHOW_PACKAGES_MODAL_CHANGED`;

const INITIAL_STATE = {
  configuratorData: null,
  product: null,
  productOptions: null,
  loadedOrder: null,

  currentCategoryId: null,
  currentSubCategoryId: null,
  highlightedOptionId: null,
  submittedCategoryIds: new Set(),
  selectedOptionIds: new Set(),
  hiddenOptionIds: new Set(),

  popupInfo: null,
  showPackagesModal: null,
};

export default {
  name: NAME,

  reducer: (state = INITIAL_STATE, action) => {
    if (action.type === ACTION_RESET) {
      if (!action.payload) {
        return INITIAL_STATE;
      }

      const { product, productOptions, selectedOptionIds, showPackagesModal } = action.payload;
      const configuratorData = makeConfiguratorData(productOptions);

      return {
        product,
        configuratorData,
        productOptions,
        selectedOptionIds,
        showPackagesModal,
        loadedOrder: null,
        currentCategoryId: (configuratorData.categories.length && configuratorData.categories[0].id) || null,
        currentSubCategoryId: null,
        hiddenOptionIds: new Set(),
        submittedCategoryIds: new Set(),
      };
    }

    if (action.type === ACTION_CHANGE_PACKAGE_CONFIG) {
      return {
        ...state,
        selectedOptionIds: new Set(action.payload),
      };
    }

    if (action.type === ACTION_LOAD_ORDER) {
      const { order, productOptions } = action.payload;

      const product = order.product;
      const configuratorData = makeConfiguratorData(productOptions);
      const selectedOptionIds = new Set(order.articleIds);

      return {
        product,
        configuratorData,
        productOptions,
        selectedOptionIds,
        loadedOrder: order,
        currentCategoryId: null,
        currentSubCategoryId: null,
        hiddenOptionIds: makeHiddenOptionIds(
          selectedOptionIds,
          state.hiddenOptionIds,
          configuratorData.rules.itemHideRules,
        ),
        submittedCategoryIds: new Set(configuratorData.categories.map(({ id }) => id)),
      };
    }

    if (action.type === ACTION_SELECTED_CATEGORY) {
      return {
        ...state,
        currentCategoryId: action.payload,
        currentSubCategoryId: null,
      };
    }

    if (action.type === ACTION_SUBMITTED_CATEGORY) {
      const categoryId = action.payload;
      const index = state.configuratorData.categories.findIndex(cat => cat.id === categoryId);
      if (index >= 0) {
        let nextUnsubmittedIndex = state.configuratorData.categories.findIndex((nextCategory, nextIndex) => {
          return nextIndex > index && !state.submittedCategoryIds.has(nextCategory.id);
        });

        if (nextUnsubmittedIndex < 0) {
          nextUnsubmittedIndex = state.configuratorData.categories.findIndex(
            (nextCategory, nextIndex) => nextIndex < index && !state.submittedCategoryIds.has(nextCategory.id),
          );
        }

        const currentCategoryId =
          (nextUnsubmittedIndex >= 0 && state.configuratorData.categories[nextUnsubmittedIndex].id) || null;

        return {
          ...state,
          currentCategoryId,
          currentSubCategoryId: null,
          submittedCategoryIds: new Set(state.submittedCategoryIds).add(categoryId),
        };
      }
    }

    if (action.type === ACTION_SELECTION_CHANGED) {
      const { optionId, selected} = action.payload;
      const item = state.productOptions.find(option => option.id === optionId);

      if (item) {
        const submittedCategoryIds = new Set(state.submittedCategoryIds);
        submittedCategoryIds.delete(item.categoryId);

        const selectedOptionIds = new Set(state.selectedOptionIds);
        if (selected) {
          selectedOptionIds.add(optionId);
          (item.mandatory || []).forEach(id => selectedOptionIds.add(id));

        }
         else {
          (item.mandatory || []).forEach(id => selectedOptionIds.delete(id));
          selectedOptionIds.delete(optionId);
       }
       if (state.configuratorData.rules.itemDeselectRules[optionId]) {
        for (const otherItemId of state.configuratorData.rules.itemDeselectRules[optionId]) {
         selectedOptionIds.delete(otherItemId);
          }
       }





        const hiddenOptionIds = makeHiddenOptionIds(
          selectedOptionIds,
          state.hiddenOptionIds,
          state.configuratorData.rules.itemHideRules,
        );

        return {
          ...state,
          selectedOptionIds,
          submittedCategoryIds,
          hiddenOptionIds,
        };
      }
    }

    if (action.type === ACTION_OPTION_HIGHLIGHT_TOGGLED) {
      const { highlightedOptionId } = state;
      const { optionId, highlighted } = action.payload;

      if (optionId === highlightedOptionId && !highlighted) {
        return {
          ...state,
          highlightedOptionId: null,
        };
      }

      if (optionId && highlighted) {
        return {
          ...state,
          highlightedOptionId: optionId,
        };
      }
    }

    if (action.type === ACTION_SELECTED_SUBCATEGORY) {
      return {
        ...state,
        currentSubCategoryId: action.payload,
      };
    }

    if (action.type === ACTION_POPUP_INFO_CHANGED) {
      return {
        ...state,
        popupInfo: action.payload,
      };
    }

    if (action.type === ACTION_SHOW_PACKAGES_MODAL_CHANGED) {
      return {
        ...state,
        showPackagesModal: action.payload,
      };
    }

    return state;
  },

  selectConfiguratorRaw: state => state[NAME],

  selectConfiguratorProduct: createSelector('selectConfiguratorRaw', ({ product }) => product),

  selectConfiguratorData: createSelector('selectConfiguratorRaw', ({ configuratorData }) => configuratorData),

  selectConfiguratorHiddenOptionIds: createSelector('selectConfiguratorRaw', ({ hiddenOptionIds }) => hiddenOptionIds),

  selectConfiguratorReady: createSelector('selectConfiguratorData', configuratorData => Boolean(configuratorData)),

  selectConfiguratorCategories: createSelector('selectConfiguratorData', data => data && data.categories),

  selectConfiguratorBaseImage: createSelector(
    'selectConfiguratorRaw',
    ({ product }) => (product && product.imageUrl) || null,
  ),

  selectConfiguratorDisplayImages: createSelector(
    'selectSelectedConfiguratorOptions',
    'selectConfiguratorHighlightedOption',
    (selectedOptions, highlightedOption) => {
      const options =
        !highlightedOption || selectedOptions.includes(highlightedOption)
          ? selectedOptions
          : [...selectedOptions, highlightedOption];

      const layers = options
        .map(option => ({
          key: option === highlightedOption ? `${option.id}:hl` : `${option.id}:n`,
          imageUrl:
            (option === highlightedOption && option.constructorHighlightedImageUrl) || option.constructorImageUrl,
          order: option.order,
        }))
        .filter(option => option.imageUrl);

      return _sortBy(layers, 'order');
    },
  ),

  selectConfiguratorCurrentCategoryId: createSelector(
    'selectConfiguratorRaw',
    ({ currentCategoryId }) => currentCategoryId,
  ),

  selectConfiguratorCurrentCategory: createSelector(
    'selectConfiguratorCategories',
    'selectConfiguratorCurrentCategoryId',
    (categories, currentId) => {
      if (!categories || !currentId) {
        return null;
      }
      return categories.find(category => category.id === currentId);
    },
  ),

  selectConfiguratorCurrentSubCategoryId: createSelector(
    'selectConfiguratorRaw',
    ({ currentSubCategoryId }) => currentSubCategoryId,
  ),

  selectConfiguratorSubmittedCategoryIds: createSelector(
    'selectConfiguratorRaw',
    ({ submittedCategoryIds }) => submittedCategoryIds,
  ),

  selectConfiguratorHighlightedOptionId: createSelector(
    'selectConfiguratorRaw',
    ({ highlightedOptionId }) => highlightedOptionId,
  ),

  selectConfiguratorHighlightedOption: createSelector(
    'selectAllConfiguratorOptions',
    'selectConfiguratorHighlightedOptionId',
    (allOptions, highlightedId) => {
      if (!highlightedId) {
        return null;
      }
      return allOptions.find(option => option.id === highlightedId);
    },
  ),

  selectConfiguratorSelectedOptionIds: createSelector(
    'selectConfiguratorRaw',
    ({ selectedOptionIds }) => selectedOptionIds,
  ),

  selectAllConfiguratorOptions: createSelector('selectConfiguratorRaw', ({ productOptions }) => productOptions),

  selectSelectedConfiguratorOptions: createSelector(
    'selectAllConfiguratorOptions',
    'selectConfiguratorSelectedOptionIds',
    (options, selectedIds) => (options || []).filter(option => selectedIds.has(option.id)),
  ),

  selectConfiguratorComplete: createSelector(
    'selectConfiguratorCategories',
    'selectConfiguratorSubmittedCategoryIds',
    (categories, submittedIds) => Boolean(categories && categories.every(c => submittedIds.has(c.id))),
  ),

  selectConfiguratorLoadedOrder: createSelector('selectConfiguratorRaw', ({ loadedOrder }) => loadedOrder),

  selectConfiguratorPopupInfo: createSelector('selectConfiguratorRaw', ({ popupInfo }) => popupInfo),

  selectShowPackagesModal: createSelector('selectConfiguratorRaw', ({ showPackagesModal }) => showPackagesModal),

  doSelectConfiguratorCategory: categoryId => ({ type: ACTION_SELECTED_CATEGORY, payload: categoryId }),

  doSelectConfiguratorSubCategory: subCategoryId => ({ type: ACTION_SELECTED_SUBCATEGORY, payload: subCategoryId }),

  doSubmitConfiguratorCategory: categoryId => ({ type: ACTION_SUBMITTED_CATEGORY, payload: categoryId }),

  doToggleConfiguratorItemSelected: (optionId, selected) => ({
    type: ACTION_SELECTION_CHANGED,
    payload: { optionId, selected },
  }),

  doToggleConfiguratorOptionHighlight: (optionId, highlighted) => ({
    type: ACTION_OPTION_HIGHLIGHT_TOGGLED,
    payload: { optionId, highlighted },
  }),

  doSetConfiguratorPopupInfo: popupInfo => ({
    type: ACTION_POPUP_INFO_CHANGED,
    payload: popupInfo,
  }),

  doSetShowPackagesModal: showModal => ({
    type: ACTION_SHOW_PACKAGES_MODAL_CHANGED,
    payload: showModal,
  }),

  doChangePackageConfig: packOptions => ({
    type: ACTION_CHANGE_PACKAGE_CONFIG,
    payload: packOptions,
  }),

  doResetConfig: () => ({
    type: ACTION_RESET,
  }),

  reactShouldResetConfigurator: createSelector(
    'selectCurrentOrder',
    'selectConfiguratorProduct',
    'selectAllConfiguratorOptions',
    'selectCurrentProduct',
    'selectCurrentProductOptions',
    'selectCurrentProductPackages',
    (currentOrder, loadedProduct, loadedOptions, currentProduct, currentProductOptions, currentProductPackages) => {
      if (currentOrder || currentProductPackages === null) {
        return false;
      }

      if (loadedProduct && !currentProduct) {
        return { type: ACTION_RESET };
      }

      if (
        currentProduct &&
        currentProductOptions &&
        currentProductPackages !== null && Boolean(currentProductPackages.length) &&
        (loadedProduct !== currentProduct || loadedOptions !== currentProductOptions)
      ) {
        return {
          type: ACTION_RESET,
          payload: {
            product: currentProduct,
            productOptions: currentProductOptions,
            selectedOptionIds:
              (Boolean(currentProductPackages.length) && new Set(currentProductPackages[0].options)) || new Set(),
            showPackagesModal: true,
          },
        };
      }

      if (
        currentProduct &&
        currentProductOptions &&
        (loadedProduct !== currentProduct || loadedOptions !== currentProductOptions)
      ) {
        return {
          type: ACTION_RESET,
          payload: {
            product: currentProduct,
            productOptions: currentProductOptions,
            selectedOptionIds: new Set(),
            showPackagesModal: null,
          },
        };
      }
    },
  ),

  reactShouldLoadOrder: createSelector(
    'selectCurrentOrder',
    'selectCurrentOrderProductOptions',
    'selectConfiguratorLoadedOrder',
    'selectAllConfiguratorOptions',
    (order, productOptions, loadedOrder, loadedProductOptions) => {
      if (order && productOptions && (loadedOrder !== order || loadedProductOptions !== productOptions)) {
        return {
          type: ACTION_LOAD_ORDER,
          payload: { order, productOptions },
        };
      }
    },
  ),
};

function makeHiddenOptionIds(selectedIds, oldHiddenIds, hideRules) {
  const newHiddenIds = new Set();

  for (let id of selectedIds) {
    if (hideRules[id]) {
      hideRules[id].forEach(childId => newHiddenIds.add(childId));
    }
  }

  return _isEqual(newHiddenIds, oldHiddenIds) ? oldHiddenIds : newHiddenIds;
}

function makeConfiguratorData(options) {
  const categories = makeConfiguratorTree(options);

  const rules = makeConfiguratorRules(categories);

  return { categories, rules };
}

function makeConfiguratorRules(categories) {
  const itemHideRules = {};
  const itemDeselectRules = {};

  categories.forEach(enterCategory);

  return { itemHideRules, itemDeselectRules };

  function enterCategory(category) {
    enterItems(category.items);
    category.subCategories.forEach(enterSubcategory);
  }

  function enterSubcategory(subCategory) {
    enterItems(subCategory.items, subCategory.multiselect);
  }

  function enterItems(items, multiselect = true) {
    for (const item of items) {
      enterItem(item);
      if (!multiselect) {
        items.forEach(otherItem => itemDeselectsItem(item.id, otherItem.id));
      }
    }
  }

  function enterItem(item) {
    if (item.hidesOptionIds) {
      for (const otherItemId of item.hidesOptionIds) {
        itemDeselectsItem(item.id, otherItemId);
        itemHidesItem(item.id, otherItemId);
      }
    }
  }

  function itemDeselectsItem(masterItemId, slaveItemId) {
    if (masterItemId !== slaveItemId) {
      itemDeselectRules[masterItemId] = itemDeselectRules[masterItemId] || new Set();
      itemDeselectRules[masterItemId].add(slaveItemId);
    }
  }

  function itemHidesItem(masterItemId, slaveItemId) {
    itemHideRules[masterItemId] = itemHideRules[masterItemId] || new Set();
    itemHideRules[masterItemId].add(slaveItemId);
  }
}

function makeConfiguratorTree(options) {
  const categories = {};

  options.forEach(option => {
    const {
      categoryId,
      categoryName,
      categoryImageUrl,
      categoryDescription,
      categoryOrder,
      subCategoryId,
      subCategoryName,
      required,
    } = option;

    let category = categories[categoryId];

    if (!category) {
      category = {
        id: categoryId,
        name: categoryName,
        imageUrl: categoryImageUrl,
        description: categoryDescription,
        items: [],
        subCategoriesHash: {},
        order: categoryOrder,
        info:
          (categoryDescription && {
            title: categoryName,
            imageUrl: categoryImageUrl,
            description: categoryDescription,
          }) ||
          null,
      };
      categories[categoryId] = category;
    }

    if (Number(subCategoryId) > 0) {
      let subCategory = category.subCategoriesHash[subCategoryId];
      if (!subCategory) {
        subCategory = {
          id: subCategoryId,
          name: subCategoryName,
          items: [],
          multiselect: !required,
          info: null,
        };
        category.subCategoriesHash[subCategoryId] = subCategory;
      }
      subCategory.items.push(option);
    } else {
      category.items.push(option);
    }
  });

  return _sortBy(
    Object.values(categories).map(({ items: categoryItems, subCategoriesHash, ...restCategory }) => ({
      ...restCategory,
      items: _sortBy(categoryItems, ['order', 'id']),
      subCategories: _sortBy(
        Object.values(subCategoriesHash).map(({ items, ...restSubCategory }) => ({
          ...restSubCategory,
          items: _sortBy(items, ['order', 'id']),
        })),
        'id',
      ),
    })),
    ['order', 'id'],
  );
}
