import React from 'react';
import { useConnect } from 'redux-bundler-hook';
import { PageContent } from '../layouts/PageContent';
import Stepper from 'react-stepper-horizontal';
import styles from './ContentHeader.module.scss';


export default function ContentRouter() {
  const {
    route: { component: Component },
    contentArgs,
  } = useConnect('selectRoute', 'selectContentArgs');

  if (contentArgs && contentArgs.fluid) {
    return <Component />;
  }

  return (

    <PageContent>

      <Component />

    </PageContent>


  );
}
