import React from 'react';
import cn from 'classnames';

import styles from './PageContentHeader.module.scss';


export default function PageContentHeader({ children, condense }) {
  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.container, { [styles.condenseContainer]: condense })}>{children}</div>{' '}
    </div>
  );
}
