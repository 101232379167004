import React from 'react';
import { useConnect } from 'redux-bundler-hook';

import { useTranslate } from '../../bundles/i18n';
import { useLinks } from '../../bundles/router';
import DefaultStepper from '../../components/Stepper/DefaultStepper';
import PrimaryButton from '../../controls/PrimaryButton';

import { PageContentHeader, PageContentHeaderButton, PageContentHeaderTitle } from '../../layouts/PageContent';
import OrderPreview from './OrderPreview';

import Hover from "../../controls/Hover";
import ProductPopover from "../../components/ProductPopover";


import styles from './ProductConstructorSubmit.module.scss';

export default function ProductConstructorSubmit() {
  const { t } = useTranslate();

  const { makeLink, makeOrderLink } = useLinks();

  let { pendingOrders } = useConnect('selectCurrentOrder', 'selectPendingOrders');

  const hasPendingOrders = Boolean(pendingOrders && pendingOrders.length);

  return (
    <div className={styles.wrapper}>
      <DefaultStepper step={1} />

      {hasPendingOrders && (
        <div className={styles.pendingOrdersContainer}>
          {pendingOrders.map(order => (
            <OrderPreview key={order.id} order={order} className={styles.subSection} />
          ))}
        </div>
      )}

        <PageContentHeader condense>

            <PageContentHeaderTitle smaller>{t('LNG_CONFIGURATION_FINISHED_TITLE')}</PageContentHeaderTitle>

            <PageContentHeaderButton route={makeOrderLink()}>
                {t('LNG_CONFIGURATION_FINISHED_BUTTON_CONTINUE')}
            </PageContentHeaderButton>
        </PageContentHeader>

      <div className={`${styles.addMoreConfigWrapper} ${styles.subSection}`}>
        <h2>{t('LNG_CONFIGURATION_FINISHED_QUESTION')}</h2>
        <div className={styles.addMoreConfigContainer}>
          <h3>{t('LNG_CONFIGURATION_FINISHED_LABEL_ADD_CONFIGURATION')}</h3>
          <PrimaryButton route={makeLink('/')}>{t('LNG_CONFIGURATION_FINISHED_BUTTON_ADD_MACHINE')}</PrimaryButton>
        </div>
      </div>
    </div>
  );
}
