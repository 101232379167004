import React from 'react';
import cn from 'classnames';
import { useConnect } from 'redux-bundler-hook';
import ProductConstructorInfoButton from './ProductConstructorInfoButton';

import styles from './ProductConstructorSubCategory.module.scss';
import ConstructorOptionCheckbox from './ConstructorOptionCheckbox';

export default function ProductConstructorSubCategory({ subCategory, isCurrent }) {
  const { doSelectConfiguratorSubCategory, configuratorSelectedOptionIds: selectedIds } = useConnect(
    'doSelectConfiguratorSubCategory',
    'selectConfiguratorSelectedOptionIds',
  );
  const handleSubmit = () => {
    if (isCurrent) {
      doSelectConfiguratorSubCategory(null);
    } else {
      doSelectConfiguratorSubCategory(subCategory.id);
    }
  };

  let content = null;

  if (isCurrent) {
    content = subCategory.items.map(option => <ConstructorOptionCheckbox key={option.id} option={option} />);
  } else {
    const selectedItems = subCategory.items.filter(item => selectedIds.has(item.id));
      //console.log({'selectedItems': selectedItems})
    if (selectedItems.length) {
      content = selectedItems.map(item => (
        <span key={`s-${item.id}`} className={styles.selectedItem}>
          {item.name}
        </span>
      ));
    }
  }

  const hasInfo = !isCurrent && Boolean(subCategory.info);
  //console.log({'subCategory': subCategory})

  return (
    <div className={cn(styles.wrapper, { [styles.wrapperSelected]: isCurrent })}>
      <button
        className={cn(styles.heading, { [styles.headingSelected]: isCurrent, [styles.headingWithInfo]: hasInfo })}
        onClick={handleSubmit}
      >
        {hasInfo && <ProductConstructorInfoButton info={subCategory.info} className={styles.infoButton} />}

        <div className={styles.titleWrapper}>
          <span className={styles.title}>{subCategory.name}</span>
        </div>
      </button>

      {content && <div className={styles.content}>{content}</div>}
    </div>
  );
}
