import { createSelector } from 'redux-bundler';
import { localizedCollection } from './helpers/mayerApiLocalization';

const EMPTY = [];

export default {
  name: 'allProducts',

  selectAllProducts: createSelector('selectMayerMainMachines', 'selectCurrentLanguage', (data, language) => {
    if (!data) {
      return EMPTY;
    }
    console.log({selectAllProducts:data});
    return localizedCollection(data, language).map(mainMachine => ({
      id: mainMachine['ArticleNr'],
      categoryId: mainMachine['Business'],

      performance: Number(mainMachine['ArticlePerformance']),
      potSize: mainMachine['PotSize'],
      minPotSize: Number(mainMachine['minPotSize']),
      maxPotSize: Number(mainMachine['maxPotSize']),

      productTypeId: mainMachine['IDCategory'],
      productTypeName: mainMachine['CategoryName'],
      name: mainMachine['ArticleName'],
      description: mainMachine['ActicleDescription'],
      details: mainMachine['ArticleDetailText'],
      price: mainMachine['ArticlePrice'],
      imageUrl: mainMachine['ArticleImageURL'],
      videoUrl: mainMachine['ArticleVideoURL'],

      __raw: mainMachine,
    }));
  }),
};
