import { AVAILABLE_LANGUAGES } from '../i18n';

export function localizedField(data, baseName, lang) {
  for (const testedLang of [lang, ...AVAILABLE_LANGUAGES]) {
    const result = tryLocalizedField(data, baseName, testedLang);
    if (result) {
      return result;
    }
  }

  return data[baseName] || null;
}

export function localizedCollection(items, lang) {
  const mayerLang = lang.toUpperCase();
  return items.filter(item => item['Language'] === mayerLang);
}

function tryLocalizedField(data, baseName, lang) {
  return (
    tryLocalizedFieldWithSuffix(data, baseName, lang.toUpperCase()) ||
    tryLocalizedFieldWithSuffix(data, baseName, lang.toLowerCase())
  );
}

function tryLocalizedFieldWithSuffix(data, baseName, suffix) {
  const key = baseName ? `${baseName}_${suffix}` : suffix;
  return data[key];
}
