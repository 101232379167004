import React from 'react';
import { useConnect } from 'redux-bundler-hook';
import cn from 'classnames';

import styles from './ProductConstructorPackageButton.module.scss';

export default function ProductConstructorPackageButton({ name, isCurrent, packOptions }) {
  const {
    doChangePackageConfig,
    configuratorComplete,
    currentOrderId,
    configuratorProduct,
    configuratorSelectedOptionIds,
    doStoreOrder,
    doUpdateOrder,
    doUpdateUrl,
    selectedConfiguratorOptions
  } = useConnect(
    'doChangePackageConfig',
    'selectConfiguratorComplete',
    'selectConfiguratorProduct',
    'selectCurrentOrderId',
    'selectConfiguratorSelectedOptionIds',
    'doStoreOrder',
    'doUpdateOrder',
    'doUpdateUrl',
    'selectSelectedConfiguratorOptions'
  );

  const calculatedTotalPrice = () => {
    const startPrice = parseFloat(configuratorProduct.price);
    const price = selectedConfiguratorOptions
      .reduce((price, option) => price + parseFloat(option.price), 0);

    return parseFloat(startPrice + price).toFixed(2)
  };

  return (
    <button
      className={ cn(styles.btn, isCurrent ? styles.current : styles.option) }
      onClick={() => doChangePackageConfig(packOptions)}
    >{name}</button>


  );
}
