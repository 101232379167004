import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { useConnect } from 'redux-bundler-hook';
import uuid from 'uuid';
import NumberFormat from 'react-number-format';

import { useTranslate } from '../../bundles/i18n';
import { useLinks } from '../../bundles/router';

import styles from './ProductConstructorFinishButton.module.scss';
import { OptionsRowMain, OptionsRowPrice } from '../../layouts/OptionsRow';
import cn from 'classnames';
import OptionsRow from '../../layouts/OptionsRow/OptionsRow';
import $ from 'jquery';

const ButtonWrapper = posed.div({
  enter: {
    opacity: 1,
    left: 0,
    transition: { type: 'spring', stiffness: 240, damping: 18 },
  },
  exit: {
    opacity: 0,
    left: '100%',
  },
});

export default function ProductConstructorFinishButton() {
  const {
    configuratorComplete,
    currentOrderId,
    configuratorProduct,
    configuratorSelectedOptionIds,
    doStoreOrder,
    doUpdateOrder,
    doUpdateUrl,
    selectedConfiguratorOptions
  } = useConnect(
    'selectConfiguratorComplete',
    'selectConfiguratorProduct',
    'selectCurrentOrderId',
    'selectConfiguratorSelectedOptionIds',
    'doStoreOrder',
    'doUpdateOrder',
    'doUpdateUrl',
    'selectSelectedConfiguratorOptions'
  );

  const { t } = useTranslate();

  const { makeProductConstructorSubmitLink } = useLinks();

  const handleSubmit = () => {
    let orderId = null;
    /*let orderAmounts = getOrderAmounts();

    console.log({orderAmounts:orderAmounts});*/

    if (currentOrderId) {
      orderId = currentOrderId;
      doUpdateOrder(orderId, Array.from(configuratorSelectedOptionIds));
    } else {
      orderId = uuid.v4();
      doStoreOrder(orderId, configuratorProduct, Array.from(configuratorSelectedOptionIds));
    }

    doUpdateUrl(makeProductConstructorSubmitLink());
  };

  const calculatedTotalPrice = () => {
    const startPrice = parseFloat(configuratorProduct.price);
    const price = selectedConfiguratorOptions
      .reduce((price, option) => price + parseFloat(option.price), 0);

    return parseFloat(startPrice + price).toFixed(2)
  };

  return (
    <OptionsRow>
      <OptionsRowMain className={cn(styles.wrapper)}>
        <PoseGroup>
          <ButtonWrapper key="submit" className={styles.buttonWrapper}>
            <button onClick={handleSubmit} className={styles.button}>
              {t('LNG_CONFIGURATION_BUTTON_INQUIRY')}
            </button>
          </ButtonWrapper>
        </PoseGroup>
      </OptionsRowMain>
      <input type={"hidden"} id={"machinePrice"} value={configuratorProduct.price} />
      <OptionsRowPrice className={cn(styles.totalPrice, 'finishPrice')}>
      <NumberFormat value={calculatedTotalPrice()} displayType={'text'}   decimalSeparator={","}
          decimalPrecision={2}
          thousandSeparator={"."} isNumericString={true}/>{" €"}

      </OptionsRowPrice>
    </OptionsRow>
  );
}

function getOrderAmounts()
{
  let retVal = [];
  let id;
  let amount;

  $('.checkboxButtonBox').each(function(){
    id = $(this).attr('id').replace('button_','');
    if($('#check_icon_'+id).css('opacity') > 0)
    {
      amount = 1;
      if($('#stepper_'+$(this).attr('id').replace('calc_price_num_','')).length)
      {
        amount = $('#stepper_'+id).val();
        retVal.push({id:id,amount:amount});
      }
    }
  });

  return retVal;
}
