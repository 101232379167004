import React from 'react';

import { useLinks } from '../../bundles/router';
import Button from '../../controls/Button';
import { useTranslate } from '../../bundles/i18n';
import styles from './ProductPopover.module.scss';
import NumberFormat from 'react-number-format';

import Buttons from 'react-bootstrap/Button';



export default function ProductPopover({ product }) {
  const { samePageNavHelper, navHelper, makeProductConstructorLink, makeProductDetailsLink } = useLinks();
  const { t } = useTranslate();

  return (
    <div className={styles.product_popover}>
        <br/><br/><br/>
        <div className={styles.product_popover__title}>
            <h2>{product.name}</h2><br/>
        <div className={styles.product_popover__description}>{product.details}</div><br /><br />

      <div className={styles.product_popover__price}>
        <p>{t('LNG_PRICE_STARTING_FROM')} <NumberFormat value={product.price} displayType={'text'}   decimalSeparator={","}
            decimalprecision={2}
            thousandSeparator={"."} isNumericString={true}/>{" €"}</p>
      </div><br/>

      <div className={styles.product_popover__buttons}>
        <Button
          className={styles.product_popover__buttons_btn}
          type="raised"
          href={makeProductDetailsLink(product)}
          onClick={samePageNavHelper}
        >
          {t('LNG_MAINPAGE_POP_BUTTON_INFORMATION')}
        </Button>
          {/*<Buttons variant="outline-primary ">Primaryyyyyyyyyyyy</Buttons>*/}
        <Button
          className={styles.product_popover__buttons_btn}
          type="raised"
          href={makeProductConstructorLink(product)}
          onClick={navHelper}
        >
          {t('LNG_MAINPAGE_POP_BUTTON_CONFIGURATION')}
        </Button>
        </div>
        </div>
    </div>
  );
}
