import React from 'react';

import MayerPageWrapper from './layouts/MayerPageWrapper';
import ContentHeader from './containers/ContentHeader';
import ContentRouter from './containers/ContentRouter';
import ResourceStatusBoundary from './controls/ResourceStatusBoundary';

export default function App() {
  return (
    <ResourceStatusBoundary bundleKey="translationsReady" spinnerType="page">
      <MayerPageWrapper>
        <ContentHeader />
        <ContentRouter />
      </MayerPageWrapper>
    </ResourceStatusBoundary>
  );
}
