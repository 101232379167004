import { createSelector } from 'redux-bundler';

const NAME = 'currentProduct';

export default {
  name: NAME,

  selectCurrentProductId: createSelector(
    'selectRouteParams',
    'selectCurrentOrder',
    (routeParams, order) => {

      if (routeParams.productId) {

        return routeParams.productId;
      }

      if (order) {

        return order.product.id;
      }
      return null;
    },
  ),

  selectCurrentProduct: createSelector(
    'selectAllProducts',
    'selectCurrentProductId',
    'selectCurrentOrder',
    (products, productId, order) => {
      console.log({selectCurrentProduct:products});
      if (order) {
        return order.product;
      }

      if (!productId) {
        return null;
      }
      return products.find(p => p.id === productId);
    },
  ),
};
