import { createAsyncResourceBundle } from 'redux-bundler-async-resources';
import { createSelector } from 'redux-bundler';
import { ONE_DAY } from './constants';

export default {
  ...createAsyncResourceBundle({
    name: 'mayerBusinessAreas',
    staleAfter: Infinity,
    expireAfter: ONE_DAY,
    getPromise: ({ mayerApiFetcher }) => mayerApiFetcher.fetchBusinessAreas(),
  }),

  reactShouldFetchMayerBusinessAreas: createSelector('selectMayerBusinessAreasIsPendingForFetch', isPending => {
    if (isPending) {

      return { actionCreator: 'doFetchMayerBusinessAreas' };
    }
  }),
};
