import React, { useState } from 'react';
import { useConnect } from 'redux-bundler-hook';
import cn from 'classnames';

import ProductConstructorCategoryHeading from './ProductConstructorCategoryHeading';
import ProductConstructorCategoryContent from './ProductConstructorCategoryCont.js';
import NumberFormat from 'react-number-format';

import styles from './ProductConstructorCategory.module.scss';

export default function ProductConstructorCategory({ category }) {
  //console.log("check4");
  const { configuratorCurrentCategoryId } = useConnect('selectConfiguratorCurrentCategoryId');
  const [contentHeight, setContentHeight] = useState(null);

  const isCurrent = category.id === configuratorCurrentCategoryId;

  const handleMeasureContentHeight = nextContentHeight => {
    if (nextContentHeight !== contentHeight) {
      setContentHeight(nextContentHeight);
    }
  };

  return (

    <div className={styles.wrapper}>
      <ProductConstructorCategoryHeading className={styles.headingWrapper} category={category} />
      <div
        className={cn(styles.contentWrapper, { [styles.contentWrapperCollapsed]: !isCurrent })}
        style={{ height: isCurrent ? contentHeight : 0, opacity: isCurrent ? 1 : 0 }}
      >
        <ProductConstructorCategoryContent category={category} onMeasureContentHeight={handleMeasureContentHeight} />
      </div>
    </div>
  );
}
