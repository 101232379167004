import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { ReduxBundlerProvider } from 'redux-bundler-hook';

import App from './App';
import createStore from './bundles';
import * as serviceWorker from './serviceWorker';
import cache from './common/cache';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';

import ReactGA from 'react-ga';
//const trackingId = "UA-106405730-1";
const trackingId = "UA-138430674-1";

ReactGA.initialize(trackingId);
ReactGA.pageview(process.env.REACT_APP_API_URL.replace("/api/mayer/process.php",""));

cache.getAll().then(initialData => {
  if (initialData) {
    console.log('starting with locally cache data:', initialData);
  }

  const store = createStore(initialData, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
  const container = document.getElementById('root');

  ReactDOM.render(
    <ReduxBundlerProvider store={store}>
      <App />
    </ReduxBundlerProvider>,
    container,
  );
});

serviceWorker.unregister();
