import { createSelector } from 'redux-bundler';

const NAME = 'productConfiguratorPreloader';
const PRELOADING_QUEUE_SIZE = 3;

const ACTION_RESET = `${NAME}.RESET`;
const ACTION_PRELOADING_STARTED = `${NAME}.PRELOADING_STARTED`;
const ACTION_PRELOADING_FINISHED = `${NAME}.PRELOADING_FINISHED`;
const ACTION_PRELOADING_FAILED = `${NAME}.PRELOADING_FAILED`;

const BLANK_STATUSES = {};
const BLANK_QUEUE = [];

export default {
  name: NAME,

  reducer: (
    state = {
      configuratorCategories: null,
      preloadingQueue: BLANK_QUEUE,
      preloadQueue: BLANK_QUEUE,
      statuses: BLANK_STATUSES,
    },
    action,
  ) => {
    if (action.type === ACTION_RESET) {
      const { configuratorCategories, baseImage } = action.payload;
      const preloadQueue = createPreloadQueue(configuratorCategories, baseImage);

      return {
        configuratorCategories,
        statuses: BLANK_STATUSES,
        preloadQueue,
        preloadingQueue: BLANK_QUEUE,
      };
    }

    if (action.type === ACTION_PRELOADING_STARTED) {
      const imageUrl = action.payload;
      const preloadQueueIndex = state.preloadQueue.indexOf(imageUrl);
      if (preloadQueueIndex >= 0) {
        return {
          ...state,
          preloadQueue: [
            ...state.preloadQueue.slice(0, preloadQueueIndex),
            ...state.preloadQueue.slice(preloadQueueIndex + 1),
          ],
          preloadingQueue: [...state.preloadingQueue, imageUrl],
        };
      }
    }

    if (action.type === ACTION_PRELOADING_FINISHED || action.type === ACTION_PRELOADING_FAILED) {
      const imageUrl = action.payload;
      const preloadingQueueIndex = state.preloadingQueue.indexOf(imageUrl);
      if (preloadingQueueIndex >= 0) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            [imageUrl]: true,
          },
          preloadingQueue: [
            ...state.preloadingQueue.slice(0, preloadingQueueIndex),
            ...state.preloadingQueue.slice(preloadingQueueIndex + 1),
          ],
        };
      }
    }

    return state;
  },

  selectConfiguratorPreloaderRaw: state => state[NAME],

  selectConfiguratorPreloaderCategories: createSelector(
    'selectConfiguratorPreloaderRaw',
    ({ configuratorCategories }) => configuratorCategories,
  ),

  selectConfiguratorPreloaderStatuses: createSelector(
    'selectConfiguratorPreloaderRaw',
    ({ statuses }) => statuses,
  ),

  selectConfiguratorPreloaderQueue: createSelector(
    'selectConfiguratorPreloaderRaw',
    ({ preloadQueue }) => preloadQueue,
  ),

  selectConfiguratorPreloaderPendingItemsCount: createSelector(
    'selectConfiguratorPreloaderRaw',
    ({ preloadingQueue, preloadQueue }) =>
      Math.min(PRELOADING_QUEUE_SIZE - preloadingQueue.length, preloadQueue.length),
  ),

  doPreloadConfiguratorImage: imageUrl => ({ dispatch, store }) => {
    dispatch({ type: ACTION_PRELOADING_STARTED, payload: imageUrl });

    const image = new Image();
    image.onload = () => dispatch({ type: ACTION_PRELOADING_FINISHED, image, payload: imageUrl });
    image.onerror = () => dispatch({ type: ACTION_PRELOADING_FAILED, image, payload: imageUrl });
    image.src = imageUrl;
  },

  reactConfiguratorPreloaderShouldReset: createSelector(
    'selectConfiguratorPreloaderCategories',
    'selectConfiguratorCategories',
    'selectConfiguratorBaseImage',
    (prevConfiguratorCategories, configuratorCategories, baseImage) => {
      if (prevConfiguratorCategories !== configuratorCategories) {
        return {
          type: ACTION_RESET,
          payload: {
            configuratorCategories,
            baseImage,
          },
        };
      }
    },
  ),

  reactConfiguratorPreloaderShouldPreloadImage: createSelector(
    'selectConfiguratorPreloaderPendingItemsCount',
    'selectConfiguratorPreloaderQueue',
    (pendingCount, preloadQueue) => {
      if (pendingCount) {
        return { actionCreator: 'doPreloadConfiguratorImage', args: [preloadQueue[0]] };
      }
    },
  ),
};

function createPreloadQueue(categories, baseImage) {
  if (!categories) {
    return BLANK_QUEUE;
  }

  const set = new Set();
  const queue = [];

  // really naive approach: first pre-load highlighted images top-to-bottom, then pre-load main images
  // in perfect case we want to also check current constructor state to be pro-active and predict which
  // images the user will need next, adjusting our queue
  enterImageUrl(baseImage);
  categories.forEach(enterCategory);
  return queue;

  function enterCategory(category) {
    enterOptions(category.items);
    category.subCategories.forEach(enterSubCategory);
  }

  function enterSubCategory(subCategory) {
    enterOptions(subCategory.items);
  }

  function enterOptions(options) {
    options.forEach(({ constructorHighlightedImageUrl }) => enterImageUrl(constructorHighlightedImageUrl));
    options.forEach(({ constructorImageUrl }) => enterImageUrl(constructorImageUrl));
  }

  function enterImageUrl(imageUrl) {
    if (!imageUrl || set.has(imageUrl)) {
      return;
    }
    set.add(imageUrl);
    queue.push(imageUrl);
  }
}
