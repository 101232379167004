import { createSelector } from 'redux-bundler';

export default {
  name: 'currentProductCategory',

  selectCurrentProductCategoryId: createSelector(
    'selectRouteParams',
    'selectProductCategories',
    'selectCurrentOrder',
    (routeParams, productCategories, order) => {
      if (order) {
        return order.product.categoryId;
      }

      if (routeParams.categoryId) {
        return routeParams.categoryId;
      }

      if (productCategories.length) {
        return productCategories[0].id;
      }
      return null;
    },
  ),

  selectCurrentProductCategory: createSelector(
    'selectCurrentProductCategoryId',
    'selectProductCategories',
    (categoryId, categories) => categories.find(c => c.id === categoryId),
  ),
};
