import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export default function Button({ type, href, onClick, children, className }) {
  return (
    <a className={classNames(styles[type], className)} href={href} onClick={onClick}>
      {children}
    </a>
  );
}

Button.defaultProps = {
  type: 'default',
  className: '',
};
