import React from 'react';

import PrimaryButton from '../../controls/PrimaryButton';

import styles from './PageContentHeaderButton.module.scss';

export default function PageContentHeaderButton(props) {
  return (
    <div className={styles.wrapper}>
      <PrimaryButton {...props} />
    </div>
  );
}
