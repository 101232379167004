import React, { useCallback } from 'react';
import { useConnect } from 'redux-bundler-hook';
import cn from 'classnames';

import styles from './ProductConstructorInfoBut.module.scss';

export default function ProductConstructorInfoBut({ info, className }) {
  const { doSetConfiguratorPopupInfo } = useConnect('doSetConfiguratorPopupInfo');

  const handleOpenOptionInfo = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      doSetConfiguratorPopupInfo(info);
    },
    [doSetConfiguratorPopupInfo, info],
  );

  return (
    <button type="button" className={cn(styles.infoBut, className)} onClick={handleOpenOptionInfo}>
      i
    </button>
  );
}
