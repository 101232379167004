import React, { useState, useCallback } from 'react';
import posed from 'react-pose';
import { useConnect } from 'redux-bundler-hook';
import { useTranslate } from '../../bundles/i18n';

import ResourceStatusBoundary from '../../controls/ResourceStatusBoundary';
import ModalView from '../../layouts/ModalView';
import ProductConstructorItemInfo from './ProductConstructorItemInfo';

import ProductConstructorMaster from './ProductConstructorMaster';
import ProductConstructorOptions from './ProductConstructorOptions';
import ProductConstructorFinishButton from './ProductConstructorFinishButton';
import ProductConstructorPackages from './ProductConstructorPackages';
import ProductConstructorPackagesNeu from './ProductConstructorPackages';

import ProductConstructorCategoryHeading from './ProductConstructorOptions/ProductConstructorCategory';
import ProductConstructorCategory from './ProductConstructorOptions/ProductConstructorCategory';



import styles from './ProductConstructor.module.scss';
import DefaultStepper from '../../components/Stepper/DefaultStepper';

const Left = posed.div({
  init: {
    x: '-50%',
    opacity: 0,
    scale: 1.2,
  },
  enter: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: { duration: 900, ease: 'circOut' },
  },
});

const Right = posed.div({
  init: {
    x: '50%',
    opacity: 0,
    scale: 1.1,
  },
  enter: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: { duration: 900, ease: 'circOut' },
  },
});

const ButtonWrapper = posed.div({
  enter: {
    opacity: 1,
    left: 0,
    transition: { type: 'spring', stiffness: 240, damping: 18 },
  },
  exit: {
    opacity: 0,
    left: '100%',
  },
});

export default function ProductConstructor({category}) {
  const { t } = useTranslate();

  const {
    configuratorPopupInfo,
    doSetConfiguratorPopupInfo,
    showPackagesModal,
    doSetShowPackagesModal,
    currentProductPackages,
    configuratorSelectedOptionIds,
    configuratorCurrentCategoryId,
    configuratorCategories
  } = useConnect(
    'selectConfiguratorPopupInfo',
    'doSetConfiguratorPopupInfo',
    'selectShowPackagesModal',
    'doSetShowPackagesModal',
    'selectCurrentProductPackages',
    'selectConfiguratorSelectedOptionIds',
    'selectConfiguratorCategories',
  );

  const handleCloseVisibleItemInfo = useCallback(() => doSetConfiguratorPopupInfo(null), [doSetConfiguratorPopupInfo]);
  const handleClosePackagesModal = useCallback(() => doSetShowPackagesModal(null), [doSetShowPackagesModal]);
  const packagesPresent = () => Boolean(currentProductPackages && currentProductPackages.length);
  const isLongerDelivery = () => {
    return !Boolean(
      currentProductPackages &&
        currentProductPackages.filter(pack => eqSet(new Set(pack.options), configuratorSelectedOptionIds)).length,
    );
  };
   //console.log({'showPackagesModal': showPackagesModal })
  return (
    <ResourceStatusBoundary bundleKey="configuratorReady" spinnerType="block">
      <DefaultStepper step={0} />

      <div className={styles.wrapper}>
        <Left pose="enter" initialPose="init" className={styles.constructorMaster}>
          <ProductConstructorMaster />
        </Left>
        <Right pose="enter" initialPose="init" className={styles.options}>

          <ProductConstructorOptions />
          <div className={styles.warning_message}>{packagesPresent() && isLongerDelivery() && <p>{t('extended_delivery_time')}</p>}</div>
          <ProductConstructorFinishButton />
        </Right>
      </div>

      <ModalView isVisible={configuratorPopupInfo} onClose={handleCloseVisibleItemInfo}>
        <ProductConstructorItemInfo info={configuratorPopupInfo} />
      </ModalView>

      <ModalView isVisible={showPackagesModal} onClose={null}>
        <>
          <p style={{fontWeight:'bold'}}>{ t('select_package') }</p>
          <ProductConstructorPackages />
          <ButtonWrapper key="submit" className={styles.buttonWrapper}>
            <button onClick={() => handleClosePackagesModal()} className={styles.button}>
              {t('LNG_CONFIGURATION_BUTTON_CONTINUE')}
            </button>
          </ButtonWrapper>
        </>
      </ModalView>
    </ResourceStatusBoundary>
  );
}

function eqSet(as, bs) {
  if (as.size !== bs.size) return false;
  for (var a of as) if (!bs.has(a)) return false;
  return true;
}
