import { createSelector } from 'redux-bundler';
import _pickBy from 'lodash/pickBy';
import _compact from 'lodash/compact';
import currentProduct from './currentProduct';
import currentProducts from './currentProducts';
import productTypes from './productTypes';
import { ALL_PRODUCT_CATEGORY } from './productCategories';

/*const INITIAL_STATE = {
  performance: 7000,
  potSize: {min:6,max:35},
  subType: [98,99]
};*/

const NAME = 'productFilter';
const ACTION_FILTER = `${NAME}.Filter`;
const BLANK = [];

export default {
  name: NAME,

  doChangeProductFilter: filterDiff => ({ store, dispatch }) => {
    const filter = {
      ...store.selectProductFilter(),
      ...filterDiff,
    };
    dispatch({
      actionCreator: 'doUpdateQuery',
      args: [formatFilter(filter), { replace: true, maintainScrollPosition: true }],


    });
  },
  /*       type: ACTION_FILTER, payload: formatFilter(filter)
        actionCreator: 'doUpdateQuery',
      args: [formatFilter(filter), { replace: true, maintainScrollPosition: true }],
           */
  /*reducer: (state = INITIAL_STATE, action) => {

    if(action.type == ACTION_FILTER)
    {
      //currentProducts.selectCurrentProducts(currentProducts,action.payload,)
        //console.log({doChangeProductFilterReducer:setFilter(action.payload)});
        //return setFilter(action.payload);
      return setFilter(action.payload);
    }

    return state;
  },*/

 selectProductFilter: createSelector(
    'selectRouteQueryParams',
    'selectFilterPerformanceRange',
    'selectFilterProductTypesMap',
    'selectFilterPotSizeRange',
    (query, performanceRange, productTypesMap, potSizeRange) => {
      const { t, s, p } = query;
      //console.log({selectProductFilter:{t,s,p}});
      return {
        productType: parseProductType(t, productTypesMap),
        potSizeRange: parsePotSizeRange(s, potSizeRange),
        minPerformance: parseMinPerformance(p, performanceRange)
      };
    },
  ),

  selectFilterPerformanceRange: createSelector('selectCurrentCategoryProducts', products =>
    products.reduce(
      (currentRange, product) =>
        (currentRange && [Math.min(currentRange[1], product.performance), Math.max(currentRange[1], product.performance)]) || [0, product.performance],
      null,
    ),
  ),

  selectFilterProductTypesMap: createSelector('selectProductTypes', types =>
    types.reduce((map, type) => {
      if (map === null) {
        const newMap = new Map();
        newMap.set(type.id, type.subTypes.map(st => st.id));
        return newMap;
      }
      map.set(type.id, type.subTypes || []);
      //console.log({productTypesMap:map});
      return map;
    }, null),
  ),

  selectFilterPotSizeRange: createSelector('selectCurrentCategoryProducts', products =>
    products.reduce(
      (currentRange, product) =>
        (currentRange && [
          Math.min(currentRange[0], product.minPotSize),
          Math.max(currentRange[1], product.maxPotSize),
        ]) || [product.minPotSize, product.maxPotSize],
      null,
    ),
  ),
};

function setFilter(payload)
{
    let filter = {};
    if(payload.t != undefined)
    {
      filter.subTypes = payload.t;
    }
    if(payload.p != undefined)
    {
      filter.performance = payload.p;
    }
    if(payload.s != undefined)
    {
      filter.potSize = payload.s
    }
    return filter;
}

function formatFilter(filter) {
  return _pickBy(
    {
      t: formatProductType(filter.productType),
      s: formatPotSizeRange(filter.potSizeRange),
      p: formatMinPerformance(filter.minPerformance),
    },
    value => value !== null && value !== undefined,
  );
}

function parsePotSizeRange(string, totalRange) {

  if (!totalRange || !string) {
    return null;
  }

  const match = string.match(/^(\d+)-(\d+)$/);
  const value = (match && [Number(match[1]), Number(match[2])]) || null;
  if (!value) {
    return totalRange;
  }
  //console.log({parsePotSizeRange2:[Math.max(totalRange[0], value[0]), Math.min(totalRange[1], value[1])]});
  //return [Math.max(totalRange[0], value[0]), Math.min(totalRange[1], value[1])];
  return {min:Math.max(totalRange[0], value[0]),max:Math.min(totalRange[1], value[1])};
}

function formatPotSizeRange(range) {
  /*return (range && `${range[0]}-${range[1]}`) || null;*/
  if(!range)
  {
    return null;
  }
  else
  {
    let retVal = null;
    if(!Array.isArray(range))
    {
      //return {min:range.value.min,max:range.value.max};
      retVal = range.min+"-"+range.max;
    }
    else
    {
      //return {min:range[0],max:range[1]};
      retVal = range[0]+"-"+range[1];
    }
    return retVal;
  }
}

function parseProductType(string, productTypesMap) {
  if (!string) {
    return null;
  }

  const tokens = string.split(':');

  if (tokens.length === 1) {
    return checkProductType({ mainType: tokens[0], subType: null }, productTypesMap);
  } else {
    return checkProductType({ mainType: tokens[0], subType: tokens[1] }, productTypesMap);
  }
}

function checkProductType(input, productTypesMap) {
  const { mainType, subType } = input;

  const subTypes = productTypesMap.get(mainType);
  if (!subTypes) {
    return null;
  }

  if (subType && !subTypes.includes(subType)) {
    return { mainType, subType: null };
  }

  return input;
}

function formatProductType(productType) {
  if (!productType) {
    return null;
  }
  //console.log({pTSub:productType.subType});
  let sT = [];
  if(productType.subType[0] !== false)
  {
     sT.push(11);
  }
  if(productType.subType[1] !== false)
  {
    sT.push(12);
  }
  //return _compact([productType.mainType, productType.subType]).join(':') || null;
  return _compact([productType.mainType, sT]).join(':') || null;
}

function parseMinPerformance(string, performanceRange) {
  if (!performanceRange) {
    return null;
  }

  const number = Number(string) || 0;
  //console.log({parseMinPerformance: { string,performanceRange }});
  return Math.min(performanceRange[1], Math.max(performanceRange[0], number));
}

function formatMinPerformance(number) {

  if (!number) {
    // also treat 0 as null
    return null;
  }
  else if(typeof number === 'object')
  {
    return String(number.value);
  }
  else
  {
    return String(number);
  }
}
