import { createSelector } from 'redux-bundler';
import { createAsyncResourceBundle } from 'redux-bundler-async-resources';
import { ONE_DAY, ONE_HOUR } from './constants';

export default {
  ...createAsyncResourceBundle({
    name: 'mayerProductTypes',

    getPromise: ({ mayerApiFetcher }) => mayerApiFetcher.fetchProductTypes(),

    retryAfter: 5 * 1000,
    staleAfter: ONE_HOUR,
    expireAfter: ONE_DAY,
  }),

  reactShouldFetchMayerProductTypes: createSelector('selectMayerProductTypesIsPendingForFetch', isPending => {
    if (isPending) {
      return { actionCreator: 'doFetchMayerProductTypes' };
    }
  }),
};
