import cn from 'classnames';
import React, { useState } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { useConnect } from 'redux-bundler-hook';
import { useTranslate } from '../../../../bundles/i18n';
import ConstructorButton from './ConstructorButton';
import NumberFormat from 'react-number-format';

import styles from './ProductConstructorCategoryHeading.module.scss';
import ProductConstructorInfoBut from './ProductConstructorInfoBut.js';
import { OptionsRowMain, OptionsRowPrice } from '../../../../layouts/OptionsRow';
import OptionsRow from '../../../../layouts/OptionsRow/OptionsRow';

const Icon = posed.span({
  init: {
    rotate: 0,
  },
  expanded: {
    rotate: 90,
  },
});

const Controls = posed.div({
  init: {
    opacity: 0,
    x: '110%',
    transition: { x: { ease: 'easeOut' }, opacity: { ease: 'easeOut' } },
  },
  expanded: {
    opacity: 1,
    x: 0,
    transition: { x: { type: 'spring', stiffness: 250, damping: 18 }, opacity: { ease: 'easeOut', duration: 800 } },
  },
});

const SubmittedIcon = posed.span({
  init: {
    opacity: 1,
  },
  expanded: {
    opacity: 0,
    transition: { duration: 100, ease: 'easeOut' },
  },
  enter: {
    scale: 1,
    transition: { type: 'spring', stiffness: 180 },
  },
  exit: {
    scale: 0.2,
  },
});

export default function ProductConstructorCategoryHeading({ category, className }) {
  const { t } = useTranslate();
  const {
    configuratorCurrentCategoryId,
    configuratorSubmittedCategoryIds,
    doSelectConfiguratorCategory,
    doSubmitConfiguratorCategory,
    selectedConfiguratorOptions,
  } = useConnect(
    'selectConfiguratorCurrentCategoryId',
    'selectConfiguratorSubmittedCategoryIds',
    'doSelectConfiguratorCategory',
    'doSubmitConfiguratorCategory',
    'selectSelectedConfiguratorOptions',
  );

  const isCurrent = category.id === configuratorCurrentCategoryId;
  const isSubmitted = configuratorSubmittedCategoryIds.has(category.id);

  const markPreviousAndSubmit = categoryId => {
    doSubmitConfiguratorCategory(configuratorCurrentCategoryId);
    doSelectConfiguratorCategory(categoryId);
  };

  const subCategoryCalculatedPrice = categoryId => {
    const price = selectedConfiguratorOptions
      .filter(option => option.categoryId === categoryId)
      .reduce((price, option) => price + parseFloat(option.price), 0);

    return parseFloat(price).toFixed(2)
  };

  return (
    <OptionsRow>

      <OptionsRowMain className={cn(styles.wrapper, className)}>
          {category.name}






      </OptionsRowMain>
        <OptionsRowPrice className={styles.borderBottom}>
          <NumberFormat value={subCategoryCalculatedPrice(category.id)} displayType={'text'}   decimalSeparator={","}
                        decimalPrecision={2}
                        thousandSeparator={"."} isNumericString={true}/>{" €"}
        </OptionsRowPrice>
    </OptionsRow>
          );
          }
