import React from 'react';
import { useConnect } from 'redux-bundler-hook';

import ResourceStatusBoundary from '../../controls/ResourceStatusBoundary';
import Product from '../Product';
import ProductFilter from './ProductFilter/ProductFilter';

import styles from './ProductList.module.scss';
import ModalView from '../../layouts/ModalView';
import { useLinks } from '../../bundles/router';
import ProductDetails from '../../components/ProductDetails';

export default function ProductList() {
  const { currentProduct, currentProducts, doUpdateUrl } = useConnect(
    'doUpdateUrl',
    'selectCurrentProduct',
    'selectCurrentProducts',
  );

  const { makeLink } = useLinks();
  const handleCloseProductDetails = () => {
    doUpdateUrl(makeLink(`/${currentProduct.categoryId}`), { maintainScrollPosition: true });
  };
  console.log({ProductList:currentProduct});
  return (
    <ResourceStatusBoundary bundleKey="currentProductsReady" spinnerType="block">
      <ProductFilter />

      <div className={styles.product_list}>
        {
            currentProducts.map((product,key) => {
               return <Product key={product.id} product={product} />;
                })
        }
      </div>

      <ModalView isVisible={Boolean(currentProduct)} onClose={handleCloseProductDetails}>
        <ProductDetails product={currentProduct} />
      </ModalView>
    </ResourceStatusBoundary>
  );
}
