import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { useConnect } from 'redux-bundler-hook';
import _sortBy from 'lodash/sortBy';
import { FaBeer } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa';
import { Container, Row, Col, Button } from 'react-bootstrap';


import Popup from "reactjs-popup";

import Spinner from '../../controls/Spinner';
import ModalView from '../../layouts/ModalView';
import styles from './OrderPreview.module.scss';
import PrimaryButton from '../../controls/PrimaryButton';
import { useTranslate } from '../../bundles/i18n';
import { useLinks } from '../../bundles/router';
import posed from 'react-pose';


import ProductConstructorItemInfo from '../ProductConstructor/ProductConstructorItemInfo';
import ConstructorOptionCheck from '../ProductConstructor/ProductConstructorOptions/ProductConstructorCategory';

import ProductConstructorPackages from '../ProductConstructor/ProductConstructorPackages';
import ProductConstructorPackagesNeu from '../ProductConstructor/ProductConstructorPackages';
import ProductConstructorCategoryNeu from '../ProductConstructor/ProductConstructorOptions/ProductConstructorCategory';

import ProductConstructorMaster from '../ProductConstructor/ProductConstructorMaster';
import ProductConstructorOptions from '../ProductConstructor/ProductConstructorOptions';
import ProductConstructorFinishButton from '../ProductConstructor/ProductConstructorFinishButton';

import ProductConstructorCategoryHeading from '../ProductConstructor/ProductConstructorOptions/ProductConstructorCategory';
import ProductConstructorCategory from '../ProductConstructor/ProductConstructorOptions/ProductConstructorCategory';

const ButtonWrapper = posed.div({
  enter: {
    opacity: 1,
    left: 0,
    transition: { type: 'spring', stiffness: 240, damping: 18 },
  },
  exit: {
    opacity: 0,
    left: '100%',
  },
});


export default function OrderPreview({ order, className, category }) {
  let { pendingOrdersOptions,configuratorPopupInfo,doRemoveItem,
  doSetConfiguratorPopupInfo,
  showPackagesModal,
  doSetShowPackagesModal,
  currentProductPackages,
  configuratorSelectedOptionIds,
  configuratorCurrentCategoryId,
  configuratorCategories,
  doSelectOrderID
 } = useConnect('selectPendingOrdersOptions',
  'selectConfiguratorPopupInfo',
  'doRemoveItem',
  'doSetConfiguratorPopupInfo',
  'selectShowPackagesModal',
  'doSetShowPackagesModal',
  'selectCurrentProductPackages',
  'selectConfiguratorSelectedOptionIds',
  'selectConfiguratorCategories',
  'doSelectOrderID');
  const { t } = useTranslate();
  const handleClosePackagesModal = useCallback(() => doSetShowPackagesModal(null), [doSetShowPackagesModal]);
  const handleCloseVisibleItemInfo = useCallback(() => doSetConfiguratorPopupInfo(null), [doSetConfiguratorPopupInfo]);

  const { makeOrderProductConstructorLink } = useLinks();

  if (!pendingOrdersOptions[order.id]) {
    return (
      <div className={cn(styles.wrapper, className)}>
        <Spinner type="sm" />
      </div>
    );
  }

  const images = [
    { id: '__base__', constructorImageUrl: order.product.imageUrl },
    ..._sortBy(pendingOrdersOptions[order.id], 'order').filter(
      option => option.constructorImageUrl && order.articleIds.includes(option.id),
    ),
  ];


    const removeOrder = (id) => {
        doRemoveItem(id);
        // console.log(`tried to remove ${id}`);
    };


  return (


    <div className={cn(styles.wrapper, className)}>
      <div className={styles.imagesContainer}>
        {images.map(({ id, constructorImageUrl }) => (
          <div key={id} className={styles.image} style={{ backgroundImage: `url(${constructorImageUrl})` }} />
        ))}
      </div>




        <div className={styles.titleContainer}>
        <h2>{order.product.name}</h2>

            <div className={styles.btn_border}>
                <Row>

                    <Col> <PrimaryButton  route={makeOrderProductConstructorLink(order.id)} out>
                        {t('LNG_CONFIGURATION_FINISHED_BUTTON_CHANGE_CONFIGURATION')}
                    </PrimaryButton>

                    </Col>
<div className={styles.t}>
    {/*<button className='button' onClick={() => removeOrder(order.id)}>Delete</button>*/}
                    <Col md="auto" ><span> <FaTrash  onClick={() => {if (window.confirm('Are you sure to delete this record?'))removeOrder(order.id)}} /></span></Col>
</div></Row>
            </div>
  <div>

        </div>

      </div>

    </div>


  );
}
