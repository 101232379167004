import React, { useRef, useEffect } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

import styles from './ModalView.module.scss';

const Shade = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 400, ease: 'easeOut' },
  },
  exit: {
    opacity: 0,
    transition: { duration: 150, ease: 'easeOut' },
  },
});

const Container = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      y: { type: 'spring', stiffness: 220, damping: 20 },
      default: { duration: 400, ease: 'easeOut' },
    },
  },
  exit: {
    y: '80%',
    opacity: 0,
    transition: { duration: 150, ease: 'easeOut' },
  },
});

const Wrapper = posed.div();

export default function ModalView({ children, onClose, isVisible }) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (isVisible) {
      disableBodyScroll(wrapperRef.current);
      return () => enableBodyScroll(wrapperRef.current);
    }
  }, [isVisible]);

  const stopPropagation = e => {
    e.stopPropagation();
  };

  return (
    <PoseGroup>
      {isVisible && (
        <Wrapper key="content" ref={wrapperRef} className={styles.wrapper}>
          <div className={styles.container}>
            <Shade className={styles.shade} onClick={onClose} />
            <Container className={styles.content} onClick={stopPropagation}>
              {onClose !== null && <button className={styles.close} onClick={onClose} />}
              {children}
            </Container>
          </div>
        </Wrapper>
      )}
    </PoseGroup>
  );
}
