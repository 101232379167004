import { createAsyncResourcesBundle } from 'redux-bundler-async-resources';
import { ONE_DAY, ONE_HOUR } from './constants';

export default createAsyncResourcesBundle({
  name: 'mayerTechnicalDetails',
  getPromise: (productId, { mayerApiFetcher }) => mayerApiFetcher.fetchTechnicalDetails({ articleNr: productId }),
  retryAfter: 5 * 1000,
  staleAfter: ONE_HOUR,
  expireAfter: ONE_DAY,
});
