import React from 'react';
import classNames from 'classnames';

import Header from './Header';
import Footer from './Footer';

import style from './MayerPageWrapper.module.scss';

export default function MayerPageWrapper({ children, fluid }) {
  return (
    <>
      <Header />

      <div className={style.layout_container}>
        <div className={classNames({ [style.layout_content_container]: !fluid, [style.layout_content_fluid]: fluid })}>
          {children}
        </div>
        
      </div>

      <Footer />
    </>

  );
}
