import React, { useMemo } from 'react';
import { useConnect } from 'redux-bundler-hook';

import styles from './ProductConstructorPackage.module.scss';
import ProductConstructorPackageButton from '../ProductConstructorPackageButton/ProductConstructorPackageButton';
import ConstructorOptionCheck from '../../ProductConstructor/ProductConstructorOptions/ProductConstructorCategory';
import ProductConstructorCategoryNeu from '../ProductConstructorOptions/ProductConstructorCategory';

import NumberFormat from 'react-number-format';


import { useTranslate } from '../../../bundles/i18n';

export default function ProductConstructorPackages({category,orderID}) {
  const { t } = useTranslate();

  let { configuratorCategories,currentProductPackages, doResetConfig,  doChangePackageConfig,
    configuratorComplete,
    currentOrderId,
    configuratorProduct,
    configuratorSelectedOptionIds,
    doStoreOrder,
    doUpdateOrder,
    doUpdateUrl,
    selectedConfiguratorOptions,
    itemsOfMayerMachineOptions,
    productOrderManagerRaw } = useConnect(
    'selectCurrentProductPackages',
    'selectConfiguratorSelectedOptionIds',
    'doResetConfig',
    'doChangePackageConfig',
    'selectConfiguratorComplete',
    'selectConfiguratorProduct',
    'selectCurrentOrderId',
    'selectConfiguratorSelectedOptionIds',
    'doStoreOrder',
    'doUpdateOrder',
    'doUpdateUrl',
    'selectSelectedConfiguratorOptions',
    'selectConfiguratorCategories',
    'selectProductOrderManagerRaw',
    'selectCurrentSelectedProductPackages',
    'selectItemsOfMayerMachineOptions'
  );

  /*console.log("currentorderID",orderID)
  console.log('in configuratorCategories', configuratorCategories);*/
  const isCurrentPackage = options => eqSet(new Set(options), configuratorSelectedOptionIds);

  const isLongerDelivery = () => {
    return !Boolean(
      currentProductPackages &&
        currentProductPackages.filter(pack => eqSet(new Set(pack.options), configuratorSelectedOptionIds)).length,
    );
  };
  // let selectedProduct;
  //   if(typeof productOrderManagerRaw!==undefined&&!!orderID){
  //     for (var i = 0; i < productOrderManagerRaw.orders.length; i++) {
  //     const tmpProduct=  productOrderManagerRaw.orders[i];
  //     if(tmpProduct.id===orderID){
  //       selectedProduct=tmpProduct;
  //       const  tmpCategory=itemsOfMayerMachineOptions[selectedProduct.product.id];
  //       const tmpConfiguratorCategories=[];
  //       for (var j = 0; j < tmpCategory.data.length; j++) {
  //         const catItem= tmpCategory.data[j];
  //         if(selectedProduct.articleIds.indexOf(catItem.ArticleNr)!==-1)
  //         {
  //             const tmpSubCategory=tmpCategory.data.find((item)=>{
  //             return catItem.IDSubCategory===item.ArticleNr;
  //             });
  //           tmpConfiguratorCategories.push(catItem);
  //         }
  //       }
  //       configuratorCategories=tmpConfiguratorCategories;
  //     }
  //     }
  //   }

  const calculatedTotalPrice = () => {
    let startPrice = 0.0;
    if(configuratorProduct && Object.keys(configuratorProduct).length){
      startPrice = parseFloat(configuratorProduct.price);
    }
    // const startPrice = parseFloat(configuratorProduct.price);
    const price = selectedConfiguratorOptions
      .reduce((price, option) => price + parseFloat(option.price), 0);

    return parseFloat(startPrice + price).toFixed(2)
  };

  const nf = new Intl.NumberFormat();

  const calculatedPrice = () => {
    const startPrice = parseFloat(configuratorProduct.price);

    const price = selectedConfiguratorOptions
      .reduce((price, option) => parseFloat(option.price), 0);

    return parseFloat(price).toFixed(2)

  };
  //console.log("check5");
  return (
    <div className={styles.wrapper}>
      <div>
        {currentProductPackages &&
          currentProductPackages.map((pack, index) => {
            return (
              <ProductConstructorPackageButton
                key={index}
                packOptions={pack.options}
                name={pack.name}
                isCurrent={isCurrentPackage(pack.options)}
              />

            );
          })}
      </div>
         <p>Gesamtpreis</p>
        <p> <NumberFormat value={calculatedTotalPrice()} displayType={'text'}   decimalSeparator={","}
          decimalPrecision={2}
          thousandSeparator={"."} isNumericString={true}/>{" €"}
</p>
         <div>
         <div className={styles.wrapper}>

           {configuratorCategories && configuratorCategories.length && configuratorCategories.map(category => (
             <ProductConstructorCategoryNeu key={category.id} category={category} type='radio' name='low'/>
           ))}
         </div>

         </div>
      <div>

      </div>
    </div>
  );
}

function eqSet(as, bs) {
  if (as.size !== bs.size) return false;
  for (var a of as) if (!bs.has(a)) return false;
  return true;
}
