import React, { Component } from 'react'
import NumericInput from 'react-numeric-input';

class NumericStepper extends Component{

  constructor(props, context) {
    super(props, context)
    this.state = { value: this.props.value};
  }

  changeValue = (value) =>{
    let state = this.setState(value);
    /*numericInputChange(valueAsNumber,valueAsString,inputElem)*/
    if(this.props.onChange !== undefined && typeof this.props.onChange == 'function'){
      this.props.onChange(value.value,value.value,this.props.id);
    }
    return state;
  }
  render() {
    return (
      <NumericInput
        id={this.props.id}
        min={this.props.min}
        max={this.props.max}
        value={this.state.value}
        onChange={value => this.changeValue({value})}
        className={this.props.className}/>
    )
  }
}
export default NumericStepper;