import React from 'react';
import { PoseGroup } from 'react-pose';
import { useConnect } from 'redux-bundler-hook';
import posed from 'react-pose';

import ConstructorOptionCheck from './ConstructorOptionCheck';
import ProductConstructorSubCategory from './ProductConstructorSubCategory';

const Content = posed.div({
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: ({ left }) => (left ? -220 : 220),
    opacity: 0,
  },
});

export default function ProductContentSwitch({ category }) {
  const { configuratorCurrentSubCategoryId } = useConnect('selectConfiguratorCurrentSubCategoryId');
  //console.log({'configuratorCurrentSubCategoryId' : configuratorCurrentSubCategoryId})

  const currentSubCategory =
    configuratorCurrentSubCategoryId && category.subCategories.find(c => c.id === configuratorCurrentSubCategoryId);
  //console.log({'currentSubCategory': currentSubCategory})


  //   let subCategories=[];
  //   const tmpSubCategories=category.subCategories;
  //   if(typeof tmpSubCategories!==undefined){
  //     subCategories=tmpSubCategories;
  //   }
  // let ProductConstructorSubCategoryElements=[];
  //   try {
  //     ProductConstructorSubCategoryElements= subCategories.map(subCategory => (
  //       <ProductConstructorSubCategory key={subCategory.id} subCategory={subCategory} />
  //     ));
  //   } catch (e) {
  //
  //   } finally {
  //
  //   }
  // if(ProductConstructorSubCategoryElements.length===0){
  //   return null;
  // }


  return (
    <div>
    <PoseGroup>
      {currentSubCategory
        ? [
            <Content key="current-content-x">
              <ProductConstructorSubCategory subCategory={currentSubCategory} isCurrent />
            </Content>,
          ]
        : [
            <Content left key="default-content">
              {category.subCategories.map(subCategory => (
                <ProductConstructorSubCategory key={subCategory.id} subCategory={subCategory} />
              ))}

              {category.items.map(option => (
                <ConstructorOptionCheck key={option.id} option={option} />
              ))}
            </Content>,
          ]}
    </PoseGroup>
    </div>
  );
}
