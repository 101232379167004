import React, { useMemo } from 'react';
import { useConnect } from 'redux-bundler-hook';
import { makeAsyncResourceBundleKeys } from 'redux-bundler-async-resources';

import Spinner from '../Spinner';

export default function ResourceStatusBoundary({
  children,
  resource,
  bundleKey,
  bundleKeyPredicate = Boolean,
  spinnerType,
}) {
  if (resource) {
   return (
      <BundleResourceStatusBoundary resource={resource} spinnerType={spinnerType}>
        {children}
      </BundleResourceStatusBoundary>
    );
  }

  return (
    <BundleKeyStatusBoundary bundleKey={bundleKey} bundleKeyPredicate={bundleKeyPredicate} spinnerType={spinnerType}>
      {children}
    </BundleKeyStatusBoundary>
  );
}

function BundleResourceStatusBoundary({ children, resource, spinnerType }) {
  const bundleKeys = useMemo(() => makeAsyncResourceBundleKeys(resource), [resource]);

  const data = useConnect(bundleKeys.selectors.isPresent);
  if (!data[bundleKeys.keys.isPresent]) {
    return <Spinner type={spinnerType} />;
  }

  return children;
}

function BundleKeyStatusBoundary({ children, bundleKey, bundleKeyPredicate, spinnerType }) {
  const [storedBundleKey, selectorName] = useMemo(
    () => [bundleKey, `select${bundleKey.charAt(0).toUpperCase() + bundleKey.slice(1)}`],
    [],
  );

  const data = useConnect(selectorName);
  if (!bundleKeyPredicate(data[storedBundleKey])) {
    return <Spinner type={spinnerType} />;
  }

  return children;
}
