import React from 'react';

import { useTranslate } from '../../bundles/i18n';
import { useLinks } from '../../bundles/router';

import styles from './ProductDetails.module.scss';

export default function ProductDetails({ product }) {
  const { t } = useTranslate();
  const { navHelper, makeProductConstructorLink } = useLinks();
  console.log({ProductDetails:product});
  return (
    <>
      <div className={styles.description}>
        <h4>{product.name}</h4>
        <p>{product.details}</p>
      </div>

      <div>
        <img src={product.imageUrl} style={{ maxWidth: '100%' }} alt="" />
      </div>

      <p>
      {product.description}
      </p>

      <div className={styles.controls}>
        <a href={'https://www.mayer.de/pflanztechnik/service#standorte'} target="_blank" className={styles.primary} onClick={navHelper} type="raised">
          {t('LNG_TECHNICAL_BUTTON_CONSULTATION')}
        </a>

        <a onClick={navHelper} className={styles.default} href={makeProductConstructorLink(product)}>
          {t('LNG_TECHNICAL_BUTTON_CONFIGURE_NOW')}
        </a>
      </div>
    </>
  );
}
