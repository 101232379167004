import React from 'react';
import posed from 'react-pose';
import { useConnect } from 'redux-bundler-hook';

import styles from './ConstructorOptionCheckbox.module.scss';
import ProductConstructorInfoBut from './ProductConstructorInfoBut';
import OptionsRow from '../../../../layouts/OptionsRow/OptionsRow';
import { OptionsRowMain, OptionsRowPrice } from '../../../../layouts/OptionsRow';
import NumberFormat from 'react-number-format';

const CheckIcon = posed.span({
  checked: {
    scale: 1,
    opacity: 1,
  },
  unchecked: {
    scale: 0.2,
    opacity: 0,
  },
});

export default function ConstructorOptionCheck({ option }) {
  const {
    configuratorSelectedOptionIds,
    configuratorHiddenOptionIds,
    doToggleConfiguratorItemSelected,
    doToggleConfiguratorOptionHighlight,
  } = useConnect(
    'selectConfiguratorSelectedOptionIds',
    'selectConfiguratorHiddenOptionIds',
    'doToggleConfiguratorItemSelected',
    'doToggleConfiguratorOptionHighlight',
  );
  const newlite = 'V2A Ketten' ;
  const newlitee = 'Befeuchteranlage mit Kugelhahn';
  const checked = configuratorSelectedOptionIds.has(option.id);
  const hidden = configuratorHiddenOptionIds.has(option.id);


  if (hidden) {
    return null;
  }

  return (
    <OptionsRow>
      <OptionsRowMain>
      <button
        className={styles.wrapper}
        onClick={() => doToggleConfiguratorItemSelected(option.id, !checked)}
        onMouseEnter={() => doToggleConfiguratorOptionHighlight(option.id, true)}
        onMouseLeave={() => doToggleConfiguratorOptionHighlight(option.id, false)}
      >







        <div className={styles.cheeck}>
          <CheckIcon pose={checked ? 'checked' : 'unchecked'} className={styles.checkIcon} />
        </div>

        <span className={styles.checkLabel}>{option.name}</span>
        {option.info && <ProductConstructorInfoBut info={option.info} />}


      </button>

      </OptionsRowMain>
      <OptionsRowPrice>  <NumberFormat value={option.price} displayType={'text'}   decimalSeparator={","}
          decimalPrecision={2}
          thousandSeparator={"."} isNumericString={true}/>{" €"}</OptionsRowPrice>



    </OptionsRow>
  );
}
