import { createAsyncResourceBundle } from 'redux-bundler-async-resources';
import { createSelector } from 'redux-bundler';
import _flatMap from 'lodash/flatMap';

import { ONE_DAY } from './resources/constants';
import { localizedField } from './helpers/mayerApiLocalization';

export default {
  ...createAsyncResourceBundle({
    name: 'currentProductPackagesResource',

    staleAfter: Infinity,
    expireAfter: ONE_DAY,

    getPromise: async ({ mayerApiFetcher, currentProductId }) =>
      await mayerApiFetcher.loadPackagesForMachine({ machine: currentProductId }),
    dependencyKey: 'currentProductId',
  }),

  selectCurrentProductPackages: createSelector(
    'selectCurrentProductPackagesResource',
    'selectCurrentLanguage',
    (data, language) => {
      if (!data) {
        return null;
      }
      return data.map(dataItem => toProductPackage(dataItem, language));
    },
  ),

  selectCurrentSelectedProductPackages: createSelector('selectProductOrderManagerRaw', (reduxState) => {
    return reduxState.selectedOrderID;
  }),


  reactShouldFetchCurrentProductPackages: createSelector(
    'selectCurrentProductPackagesResourceIsPendingForFetch',
    isPending => {
      if (isPending) {
        return { actionCreator: 'doFetchCurrentProductPackagesResource' };
      }
    },
  ),
};

function toProductPackage(dataItem, language) {
  const optionsData = JSON.parse(dataItem.config);

  return {
    name: localizedField(dataItem, 'name', language),
    options: _flatMap(optionsData, mainRecord => mainRecord.mo.map(record => record.machineoption)),
  };
}
