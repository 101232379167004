import React from 'react';

import styles from './ProductConstructorItemInfo.module.scss';

export default function ProductConstructorItemInfo({ info }) {
  return (
    <div className={styles.description}>
      <h4>{info.title}</h4>
      {Boolean(info.imageUrl) && (
        <div className={styles.imageWrapper}>
          <img src={info.imageUrl} style={{ maxWidth: '100%' }} alt="" />
        </div>
      )}
      <p>{info.description}</p>
    </div>
  );
}
