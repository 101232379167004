import cn from 'classnames';
import React, { useState } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { useConnect } from 'redux-bundler-hook';
import { useTranslate } from '../../../../bundles/i18n';
import ConstructorButton from './ConstructorButton';
import NumberFormat from 'react-number-format';


import ConstructorOptionCheckbox from './ConstructorOptionCheckbox';


import styles from './ProductConstructorCategoryHeading.module.scss';
import ProductConstructorInfoButton from './ProductConstructorInfoButton';
import { OptionsRowMain, OptionsRowPrice } from '../../../../layouts/OptionsRow';
import OptionsRow from '../../../../layouts/OptionsRow/OptionsRow';


const Icon = posed.span({
  init: {
    rotate: 0,
  },
  expanded: {
    rotate: 90,
  },
});

const Controls = posed.div({
  init: {
    opacity: 0,
    x: '110%',
    transition: { x: { ease: 'easeOut' }, opacity: { ease: 'easeOut' } },
  },
  expanded: {
    opacity: 1,
    x: 0,
    transition: { x: { type: 'spring', stiffness: 250, damping: 18 }, opacity: { ease: 'easeOut', duration: 800 } },
  },
});

const SubmittedIcon = posed.span({
  init: {
    opacity: 1,
  },
  expanded: {
    opacity: 0,
    transition: { duration: 100, ease: 'easeOut' },
  },
  enter: {
    scale: 1,
    transition: { type: 'spring', stiffness: 180 },
  },
  exit: {
    scale: 0.2,
  },
});

export default function ProductConstructorCategoryHeading({ category, className }) {
  const { t } = useTranslate(   );
  const {
    num,
    configuratorCurrentCategoryId,
    configuratorSubmittedCategoryIds,
    doSelectConfiguratorCategory,
    doSubmitConfiguratorCategory,
    selectedConfiguratorOptions,
  } = useConnect(
    'selectConfiguratorCurrentCategoryId',
    'selectConfiguratorSubmittedCategoryIds',
    'doSelectConfiguratorCategory',
    'doSubmitConfiguratorCategory',
    'selectSelectedConfiguratorOptions',
  );

  const isCurrent = category.id === configuratorCurrentCategoryId;
  const isSubmitted = configuratorSubmittedCategoryIds.has(category.id);

  const markPreviousAndSubmit = categoryId => {
    doSubmitConfiguratorCategory(configuratorCurrentCategoryId);
    doSelectConfiguratorCategory(categoryId);
  };

  const subCategoryCalculatedPrice = categoryId => {
    const price = selectedConfiguratorOptions
      .filter(option => option.categoryId === categoryId)
      .reduce((price, option, num) => price + parseFloat(option.price), 0);
    return parseFloat(price).toFixed(2)
  };

  return (
    <OptionsRow>
      <OptionsRowMain className={cn(styles.wrapper, className)}>
        <button className={styles.titleButton} disabled={isCurrent} onClick={() => markPreviousAndSubmit(category.id)}>
          <Icon className={styles.icon} initialPose="init" pose={isCurrent ? 'expanded' : 'init'} />
          {category.name}

        </button>
        <Controls className={styles.controls} pose={isCurrent ? 'expanded' : 'init'} initialPose="expanded">
          {Boolean(category.info) && (
            <ProductConstructorInfoButton className={styles.infoButton} info={category.info} />
          )}


          <ConstructorButton
            className={styles.submitButton}
            title={t('LNG_CONFIGURATION_BUTTON_CONTINUE')}
            onClick={() => doSubmitConfiguratorCategory(category.id)}
            activated={!isSubmitted}
          />


        </Controls>

        <PoseGroup>
          {isSubmitted && (
            <SubmittedIcon
              key="submitted"
              initialPose="init"
              pose={isCurrent ? 'expanded' : 'init'}
              className={styles.submittedIcon}
            />
          )}
        </PoseGroup>
      </OptionsRowMain>
      <OptionsRowPrice className={cn(styles.borderBottom,'headerPriceRow')}>
      <NumberFormat value={subCategoryCalculatedPrice(category.id)} displayType={'text'}  decimalPrecision={2} decimalSeparator={","}
          thousandSeparator={"."} isNumericString={true}/>{" €"}

      </OptionsRowPrice>
    </OptionsRow>
  );
}
