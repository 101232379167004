import React from 'react';
import './Footer.css';

import { AVAILABLE_LANGUAGES, useTranslate } from '../../bundles/i18n';

export default function Footer() {
  const { t } = useTranslate();

  return(
      <div className="footer">
        <div className="footer-overlay">
          <div className="footer-widget-container1">
            <ul className="footer-list">
              <li className="footer-list-item">
                <a className="footer-link" href="https://mayer.de/pflanztechnik/impressum-pflanztechnik/">
                  <span className="footer-list-text">{t('LNG_MAINPAGE_FOOTER_IMPRINT')}</span>&nbsp;&nbsp;
                </a>
              </li>
              <li className="footer-list-item">
                <a className="footer-link" href="https://mayer.de/pflanztechnik/datenschutz-pflanztechnik/">&nbsp;&nbsp;
                  <span className="footer-list-text">{t('LNG_MAINPAGE_FOOTER_PRIVACY')}</span>&nbsp;&nbsp;
                </a>
              </li>
              <li className="footer-list-item">
                <a className="footer-link" href="https://mayer.de/pflanztechnik/agb/">&nbsp;&nbsp;
                  <span className="footer-list-text">{t('LNG_MAINPAGE_FOOTER_CONDITIONS')}</span>&nbsp;&nbsp;
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-widget-container2">
            <p className="footer-small-text">{t('LNG_MAINPAGE_FOOTER_COPYRIGHT')}</p></div>
        </div>
      </div>
  );

}

