import React, { useCallback } from 'react';
import { useConnect } from 'redux-bundler-hook';

import { useTranslate } from '../../bundles/i18n';
import { useLinks } from '../../bundles/router';
import PrimaryButton from '../../controls/PrimaryButton';

import styles from './LoadOrder.module.scss';

export default function LoadOrder() {
  const { doUpdateUrl } = useConnect('doUpdateUrl');
  const { t } = useTranslate();
  const { makeLoadOrderLink } = useLinks();

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      doUpdateUrl(makeLoadOrderLink(event.target.code.value));
    },
    [doUpdateUrl, makeLoadOrderLink],
  );

  return (
    <div className={styles.wrapper}>
      <h3>{t('LNG_LOADORDER_TITLE')}</h3>
      <form className={styles.form} onSubmit={handleSubmit}>
        <input
          autoFocus
          name="code"
          type="text"
          required
          className={styles.input}
          placeholder={t('LNG_LOADORDER_INPUT')}
        />
        <PrimaryButton isSubmit className={styles.submit} type="submit">
          {t('LNG_LOADORDER_SUBMIT')}
        </PrimaryButton>
      </form>
    </div>
  );
}
