import React, { useCallback, useMemo, useState } from 'react';
import { useConnect } from 'redux-bundler-hook';
import { useTranslate } from '../../../bundles/i18n';
import RangeSlider from '../../../controls/RangeSlider/RangeSlider';
import Checkbox from '../../../controls/Checkbox/Checkbox';
import currentProducts from '../../../bundles/currentProducts';
import styles from './ProductFilter.module.scss';
import ProductFilterItem from './ProductFilterItem';
/*import '../../../controls/Checkbox/checkbox.css';*/
import $ from 'jquery';

export default function ProductFilter() {
  const { t } = useTranslate();

  const allMainProductTypesLabel = t('LNG_MAINPAGE_FILTER_MACHINETYPE_ALL');
    const {
        productFilter,
        filterPotSizeRange,
        filterPerformanceRange,
        productTypes,
        doChangeProductFilter,
        currentProducts,
    } = useConnect(
        'selectProductFilter',
        'selectFilterPotSizeRange',
        'selectFilterPerformanceRange',
        'selectProductTypes',
        'doChangeProductFilter',
        'selectCurrentProducts',
    );

    const [runningMinPerformance, setRunningMinPerformance] = useState(7000);
    const [runningPotSizeRange, setRunningPotSizeRange] = useState( { min:5,max:67 });
    const [subTypeFilter, setSubTypeFilter] = useState([false,false]);

    let currentMinPerformance;
    currentMinPerformance = useMemo(() => {
        if (runningMinPerformance !== null) {
            return runningMinPerformance;
        }
        return null;
    }, [productFilter.minPerformance, runningMinPerformance]);

    const toggleSubTypeFilter = useCallback((value,idx) => {
        if(subTypeFilter[idx] === false) // CB:false => set to true
        {
            subTypeFilter[idx] = value;
        }
        else // CB: true => set to false
        {
            subTypeFilter[idx] = false;
        }
        //return doChangeProductFilter({ productType: { mainType: 6, subType: subTypeFilter } })
        filterProducts();
        return setProductSubType({subTypeFilter});
    }, [setProductSubType, productFilter.productType]);

    const currentPotSizeRange = useMemo(() => {
        if (runningPotSizeRange !== null) {
            //return runningPotSizeRange;
          {/*return {min:14,max:15};*/}
          return 0;
        }
        return null;
    }, [productFilter.potSizeRange, runningPotSizeRange]);

    const setMinPerformance = useCallback(
        value => {
            //setRunningMinPerformance(null);
            //console.log({setMinPerformance:value.value});
            setCurrentValue("performance",value.value);
            filterProducts();
            //doChangeProductFilter({ minPerformance: value });
        },
        [setRunningMinPerformance, doChangeProductFilter],
    );

    const setPotSizeRange = useCallback(
        value => {
            setRunningPotSizeRange(currentPotSizeRange);
          {/*setCurrentValue("potSize",JSON.stringify(value.value));*/}
            setCurrentValue("potSize",value.value);
            filterProducts();
            //doChangeProductFilter({ potSizeRange: value });
        },
        [setRunningPotSizeRange, doChangeProductFilter],
    );

    const setMainProductType = useCallback(value => doChangeProductFilter({ productType: { mainType: value } }), [doChangeProductFilter]);
    const setProductSubType = useCallback((value) => {
        //console.log({filter:productFilter, value:value});

      return doChangeProductFilter({ productType: { mainType: 6, subType: value } })

    }, [doChangeProductFilter, productFilter.productType]);

    const mainProductTypeDropdownOptions = useMemo(
        () => [
            { value: null, label: allMainProductTypesLabel },
            ...productTypes.map(({ id, name }) => ({
                value: id,
                label: name,
            })),
        ],
        [productTypes, allMainProductTypesLabel],
    );

    const productSubTypeDropdownOptions = useMemo(
        () => {
            const mainType = productTypes.find(t => t.id === (productFilter.productType && productFilter.productType.mainType));

            if (mainType && mainType.subTypes && mainType.subTypes.length) {
                return [
                    { value: null, label: allMainProductTypesLabel },
                    ...mainType.subTypes.map(subType => ({
                        value: subType.id,
                        label: subType.name
                    }))
                ]
            }
            return null;
        },

        [productTypes, productFilter.productType, allMainProductTypesLabel]
    );

    const handlePotSizeRangeFilterEnabledChanged = useCallback(
        enabled => {
            if (enabled) {
                doChangeProductFilter({ potSizeRange: filterPotSizeRange });
            } else {
                doChangeProductFilter({ potSizeRange: null });
            }
        },
        [doChangeProductFilter, filterPotSizeRange],
    );

    const showRangeFilter = useMemo(
        () =>  {
            return null;
        }
        //filterPotSizeRange && filterPotSizeRange[0] !== filterPotSizeRange[1], [filterPotSizeRange]
    );

    const showPerformanceFilter = useMemo(
        () => {
            //return null;
            return filterPerformanceRange[0] !== filterPerformanceRange[1]
        }
    );

  const initProducts = useMemo(
    () => {
      let url = window.location.href;
      let tab = url.substring(url.lastIndexOf('/') + 1);
      if($("#selectedTab").val() != tab)
      {
        setTimeout(filterProducts,800);
      }
    },
  )

    if (!filterPotSizeRange || !filterPerformanceRange) {
        return null;
    }

    return (
        <div className={styles.wrapper} >
            {/*<ProductFilterItem label={t('LNG_MAINPAGE_FILTER_MACHINETYPE_TITLE')}>
        <Dropdown
          options={mainProductTypeDropdownOptions}
          value={productFilter.productType && productFilter.productType.mainType || null}
          onChange={setMainProductType}
          placeholder={allMainProductTypesLabel}setProductSubType
        />
        {Boolean(productSubTypeDropdownOptions) &&
          <Dropdown
            options={productSubTypeDropdownOptions}
            value={productFilter.productType && productFilter.productType.subType || null}
            onChange={setProductSubType}
            placeholder={allMainProductTypesLabel}
          />
        }
      </ProductFilterItem>

      { showPerformanceFilter &&
        <ProductFilterItem
        label={t('LNG_MAINPAGE_FILTER_PERFORMANCE_TITLE')}
        value={currentMinPerformance && String(currentMinPerformance)}

        >
          {filterPerformanceRange[1] > filterPerformanceRange[0] && (
            <Slider
              value={currentMinPerformance}
              onChange={setMinPerformance}
              onRunningChange={setRunningMinPerformance}
              min={filterPerformanceRange[0]}
              max={filterPerformanceRange[1]}
              highlightTrackIndex={0}
              step={100}
            />
          )}
        </ProductFilterItem>
      }

      { showRangeFilter &&
        <ProductFilterItem
          enabled={productFilter.potSizeRange !== null}
          onEnabledChanged={handlePotSizeRangeFilterEnabledChanged}
          label={t('LNG_MAINPAGE_FILTER_POTSIZE_TITLE')}
          value={currentPotSizeRange && `${currentPotSizeRange[0]} - ${currentPotSizeRange[1]}`+' in cm'}
        >
          {Boolean(currentPotSizeRange) && filterPotSizeRange[1] > filterPotSizeRange[0] && (
            <Slider
              value={currentPotSizeRange}
              onChange={setPotSizeRange}
              onRunningChange={setRunningPotSizeRange}
              min={filterPotSizeRange[0]}
              max={filterPotSizeRange[1]}
              highlightTrackIndex={1}
            />
          )}
        </ProductFilterItem>
      }*/}
            <input id={"selectedTab"} type={"hidden"} value = {0} />
            <div className={styles.FacilityType} id={"container_facility"}>
                <div className={styles.FacilityTypeTitle} style={{fontWeight:"normal"}}>
                    {t('LNG_MAINPAGE_FILTER_COMPANY_TYPE_TITLE')}
                </div><br />
                <div className={styles.FacilityTypeFilter}>
                    <Checkbox id={"filter_tree_nursery"} value={11} valIdx={0} callback={toggleSubTypeFilter} checked={subTypeFilter[0]} label={t('LNG_MAINPAGE_FILTER_TREE_NURSERY_TITLE')}  />&nbsp;&nbsp;
                    <Checkbox id={"filter_ornamented_plants"} value={12} valIdx={1} callback={toggleSubTypeFilter} checked={subTypeFilter[1]} label={t('LNG_MAINPAGE_FILTER_ORNAMENTAL_PLANTS_TITLE')} />
                </div>
            </div>
            <div className={styles.Capacity} id={"container_performance"} style={{display:"none"}}>
                <div className={styles.CapacityTitle} style={{fontWeight:"normal"}}>
                    {t('LNG_MAINPAGE_FILTER_PERFORMANCE_TITLE2')}
                </div>
                <div className={styles.CapacityFilter}>
                    <input id={"performance"} type={"hidden"} value={0} />
                    <div style={{width:"300px"}}>
                      <div style={{float:"right",width:"80px"}}>
                        <input type={"text"} readOnly={"1"} id={"performance_display"} className="labelPerformance textCenter"
                               style={{border:"1px solid #036735",width:"60px",height:"22px",margin:"0 0 0 5px"}} />
                      </div>
                      <span className={"labelPerformance"} style={{float:"left",width:"60px",fontSize:"12px",paddingTop:"4px"}}><b>{t('LNG_MAINPAGE_FILTER_PERFORMANCE_TITLE')}</b></span>
                      <span style={{width:"180px"}}>
                        <RangeSlider maxValue={filterPerformanceRange[1]} minValue={0} value={0} step={100} formatLabel={""} onChange={setMinPerformance}
                                     onRunningChange={setRunningMinPerformance} id={"filter_performance"} />
                      </span>
                    </div>
                </div>
            </div>
            <div className={styles.Potsize} id={"container_potsize"} style={{display:"none"}}>
                <div className={styles.PotsizeTitle} style={{fontWeight:"normal"}}>
                    {t('LNG_MAINPAGE_FILTER_POTSIZE_TITLE')}
                </div>
                <div className={styles.PotsizeFilter}>
                  {/*<input id={"potSize"} type={"hidden"} value={JSON.stringify({min:14,max:15})} />*/}
                  <input id={"potSize"} type={"hidden"} value={0} />
                  <div style={{width:"300px"}}>
                    <div style={{float:"right",width:"80px"}}>
                      <input type={"text"} readOnly={"1"} id={"potSize_display"} className="labelPerformance textCenter"
                             style={{
                               border: "1px solid #036735",
                               width: "60px",
                               height: "22px",
                               margin: "0 0 0 5px",
                             }}/>
                    </div>
                    <span className={"labelPerformance"} style={{ float: "left", width: "60px",fontSize:"12px",paddingTop:"4px" }}><b>&Oslash; cm</b></span>
                    <span style={{width:"180px"}}>
                      {/*<RangeSlider maxValue={67} minValue={5} value={currentPotSizeRange} step={1} formatLabel={"cm"} onChange={setPotSizeRange}
                                   onRunningChange={setRunningPotSizeRange} id={"filter_potsize"}/>*/}
                      <RangeSlider maxValue={67} minValue={0} value={0} step={1} formatLabel={"cm"} onChange={setPotSizeRange}
                                   onRunningChange={setRunningPotSizeRange} id={"filter_potsize"}/>
                    </span>
                  </div>

                </div>
            </div>
    </div>
  );

    function setCurrentValue(id,value)
    {
      $("#"+id).val(value);
      //console.log($('#'+id).val());
    }

    function filterProducts()
    {
      let url = window.location.href;
      let tab = url.substring(url.lastIndexOf('/') + 1);
      tab = tab.substring(0,1);
      let ignoreSlider = true;
      if(tab == undefined || tab == null || tab == "" || tab == false)
      {
        tab = "0";
      }
      if($("#selectedTab").val() != tab)
      {
        $('#selectedTab').val(tab);
      }
      switch(tab)
      {
        case "0":
          $('#container_facility').show();
          $('#container_performance').hide();
          $('#container_potsize').hide();
          break;
        case "6":
          $('#container_facility').show();
          $('#container_performance').show();
          $('#container_potsize').show();
          ignoreSlider = false;
          break;
        default:
          $('#container_facility').hide();
          $('#container_performance').hide();
          $('#container_potsize').hide();
          break;
      }
      $('#performance_display').val($('#performance').val());
      $('#potSize_display').val($('#potSize').val());
      //let potsize_disp = JSON.parse($('#potSize').val());
      //console.log(potsize_disp);
      //$('#potSize_display').val(potsize_disp.min+"-"+potsize_disp.max);
      //console.log("filterProducts:",$("#selectedTab").val(),tab);
      $('.singleProduct').each(function(){
        let check = [true,true,true];
        /*if((!$('#filter_tree_nursery').is(':checked') && !$('#filter_ornamented_plants').is(':checked')) ||
           ($('#filter_tree_nursery').is(':checked') && $('#filter_ornamented_plants').is(':checked')))
        {
          if($(this).attr('catId') == '98' || $(this).attr('catId') == '99')
          {
            check[0] = false;
          }
        }
        else*/
        if($('#filter_tree_nursery').is(':checked') && !$('#filter_ornamented_plants').is(':checked') && $(this).attr('catId') != '98')
        {
          check[0] = false;
        }
        else if(!$('#filter_tree_nursery').is(':checked') && $('#filter_ornamented_plants').is(':checked') && $(this).attr('catId') != '99')
        {
          check[0] = false;
        }
        let range = JSON.parse($('#potSize').val());
        if(!ignoreSlider)
        {
          //if(($('#performance').val()<$(this).attr('performance') && $(this).attr('performance') != 0))
          console.log({filterPerformance:parseInt($('#performance').val()),value:parseInt($(this).attr('performance'))});
          if(parseInt($('#performance').val())>parseInt($(this).attr('performance')) && parseInt($(this).attr('performance')) != 0)
          {
            check[1] = false;
          }
          //if(range.min<$(this).attr('minpotsize') || range.max>$(this).attr('maxpotsize'))
          console.log({filterPotSize:parseInt($('#potSize').val()),value:parseInt($(this).attr('maxpotsize'))});
          if(parseInt($('#potSize').val())>parseInt($(this).attr('maxpotsize')))
          {
            check[2] = false
          }
        }
        console.log({check:checkMatrix(check),id:$(this).attr('id'),
          catId:$(this).attr('catId'),
          setPerformance:$('#performance').val(),
          machinePerformance:$(this).attr('performance'),
          setRange:range,
          minPotSize:$(this).attr('minpotsize'),
          maxPotSize:$(this).attr('maxpotsize'),
           checkArr:check});
        if(checkMatrix(check))
        {
          $(this).show();
        }
        else
        {
          $(this).hide();
        }
      });
    }

    function checkMatrix(matrix)
    {
      let ret = true;
      for(let i=0;i<matrix.length;i++)
      {
        if(!matrix[i])
        {
          ret = false;
        }
      }
      return ret;
    }
}
