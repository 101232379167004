import React, { useEffect } from 'react';
import useResizeAware from 'react-resize-aware';

import styles from './ProductConstructorCategoryContent.module.scss';
import ProductContentSw from './ProductContentSw';

export default function ProductConstructorCategoryContent({ category, onMeasureContentHeight }) {
  const [ResizeListener, sizes] = useResizeAware();

  useEffect(() => {
    onMeasureContentHeight(sizes.height);
  }, [sizes.height]);

  return (
    <div className={styles.wrapper} style={{ position: 'relative' }}>
      {ResizeListener}
      <ProductContentSw category={category} />
    </div>
  );
}
