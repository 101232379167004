import React from 'react';
import { useConnect } from 'redux-bundler-hook';
import Stepper from 'react-stepper-horizontal';

import styles from './OrderSuccess.module.scss';
import { useTranslate } from '../../bundles/i18n';
import { PageContentHeader, PageContentHeaderButton, PageContentHeaderTitle } from '../../layouts/PageContent';

export default function OrderSuccess() {
  const { t } = useTranslate();

  const { orderManagerOrderCode } = useConnect('selectOrderManagerOrderCode');

  return (
    <>
      <PageContentHeader condense>
        <PageContentHeaderTitle smaller>{t('LNG_THANK_YOU_PAGE_LABEL_THANK')}</PageContentHeaderTitle>
      </PageContentHeader>

      <div className={styles.wrapper}>
        <p>{`${t('LNG_THANK_YOU_PAGE_TEXT_DATA_TRANSFERED')} ${t('LNG_THANK_YOU_PAGE_TEXT_EMAIL_INFO')}`}</p>

        <div className={styles.buttonsWrapper}>
          {orderManagerOrderCode && (
            <div className={styles.buttonsContainer}>
              <div>
                <p>{t('LNG_TY_PAGE_SUB_1')}</p>
                <a
                  href={orderManagerOrderCode && orderManagerOrderCode.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('LNG_TY_PAGE_BUTTON_REVIEW')}
                </a>
              </div>
              <div>
                <p>{t('LNG_TY_PAGE_SUB_2')}</p>
                <a
                  href={orderManagerOrderCode && orderManagerOrderCode.continueConfiguration}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('LNG_TY_PAGE_BUTTON_CONTINUE')}
                </a>
              </div>
            </div>
          )}

          {orderManagerOrderCode && (
            <div className={styles.codeContainer}>
              <a
                href={orderManagerOrderCode && orderManagerOrderCode.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={orderManagerOrderCode && orderManagerOrderCode.imageUrl}
                  alt={t('LNG_THANK_YOU_PAGE_TEXT_CONFIG_CODE')}
                />
              </a>
            </div>
          )}

          {orderManagerOrderCode && (
            <div className={styles.buttonsContainer}>
              <div>
                <a
                  href={orderManagerOrderCode && orderManagerOrderCode.imageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('LNG_THANK_YOU_PAGE_BUTTON_DOWNLOAD_CODE')}
                </a>
              </div>
            </div>
          )}
        </div>

        <h5>{t('LNG_THANK_YOU_PAGE_TEXT_CONFIG_CODE')}</h5>
        <h4>{orderManagerOrderCode && orderManagerOrderCode.configCode}</h4>
      </div>
    </>
  );
}
