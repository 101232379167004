import React, {useState} from 'react';
import posed from 'react-pose';
import { useConnect } from 'redux-bundler-hook';
import ReactDOM from 'react-dom';
import styles from './ConstructorOptionCheckbox.module.scss';
import ProductConstructorInfoButton from './ProductConstructorInfoButton';
import OptionsRow from '../../../../layouts/OptionsRow/OptionsRow';
import { OptionsRowMain, OptionsRowPrice } from '../../../../layouts/OptionsRow';
import NumberFormat from 'react-number-format';
/*import NumericInput from 'react-numeric-input';*/
import NumericStepper from '../../../../controls/NumericStepper/NumericStepper';
import 'rc-input-number/assets/index.css';
import './ConstructorOptionCheckbox.css';
import $ from 'jquery';

const CheckIcon = posed.span({
  checked: {
    scale: 1,
    opacity: 1,
  },
  unchecked: {
    scale: 0.2,
    opacity: 0,
  },
});

export default function ConstructorOptionCheckbox({ option }) {
  //console.log({option:option});
  const {
    configuratorSelectedOptionIds,
    configuratorHiddenOptionIds,
    doToggleConfiguratorItemSelected,
    doToggleConfiguratorOptionHighlight,
  } = useConnect(
    'selectConfiguratorSelectedOptionIds',
    'selectConfiguratorHiddenOptionIds',
    'doToggleConfiguratorItemSelected',
    'doToggleConfiguratorOptionHighlight',
  );
  const newlite = 'V2A Ketten' ;
  const newlitee = 'Befeuchteranlage mit Kugelhahn';
  const checked = configuratorSelectedOptionIds.has(option.id);
  const hidden = configuratorHiddenOptionIds.has(option.id);
const [num, setNum] = useState(1);

  if (hidden) {
    return null;
  }

  if(option.amount < 1)
  {
    option.amount = 1;
  }

  return (
    <OptionsRow>

      <table cellpadding={0} cellspacing={0} border={0}>
        <body>
          <tr>
            <td className={"tableCol1"}>
              <OptionsRowMain>
                <button
                  id={"button_"+option.id}
                  className={styles.wrapper +' checkboxButtonBox'}
                  onClick={() => { doToggleConfiguratorItemSelected(option.id, !checked);  setTimeout(()=>{setChecked( option.id,function(){recalculatePrices(option.id);});},500);}}
                  onMouseEnter={() => doToggleConfiguratorOptionHighlight(option.id, true)}
                  onMouseLeave={() => doToggleConfiguratorOptionHighlight(option.id, false)}
                >
                  <div className={styles.check}>
                    <CheckIcon pose={checked ? 'checked' : 'unchecked'} className={styles.checkIcon} id={'check_icon_'+option.id} type="radio" name="gleich"/>
                  </div>
                  <span className={styles.checkLabel}>{option.name}</span>
                </button>
              </OptionsRowMain>
            </td>
            <td className={"tableCol2"}>
              {(option.multipleOrder == 1 && checked)?<NumericStepper id={"stepper_"+option.id} min={1} max={10} value={option.amount?option.amount:1} className={"numeric_stepper"} onChange={numericInputChange} />:""}
            </td>
            <td className={"tableCol3"}>

              <input type={"hidden"} id={"raw_price_"+option.id} value={option.price} />
              <input type={"hidden"} id={"calc_price_num_"+option.id} value={option.price} className={"calc_price_numeric"}/>
              <div id={"price_container_"+option.id}>

                <OptionsRowPrice>
                  {option.info && <ProductConstructorInfoButton info={option.info} />}
                  <NumberFormat className={"item_calc_price"} id={"price_"+option.id} value={option.price} displayType={'text'}   decimalSeparator={","}
                                               decimalPrecision={2}
                                               thousandSeparator={"."} isNumericString={true}/>{" €"}</OptionsRowPrice>
              </div>
            </td>
          </tr>

        </body>

      </table>





    </OptionsRow>
  );
  recalculatePrices(option.id);

  function numericInputChange(valueAsNumber,valueAsString,inputItemID)
  {
    option.amount = valueAsNumber;
    const id = $('#'+inputItemID).attr('id').replace('stepper_','');
    const raw_price = $('#raw_price_'+id).val();
    const calc_price = parseFloat(raw_price)*valueAsNumber;
    const retVal = formatMoney(calc_price,2,",",".");
    //console.log({id:id,raw_price:parseFloat(raw_price),calc_price:calc_price,retVal:retVal,valueAsNumber:valueAsNumber,valueAsString:valueAsString,inputItemID:inputItemID});
    $('#calc_price_num_'+id).val(calc_price);
    $('#price_'+id).html(retVal);
    recalculatePrices(id);
    //console.log({optionChanged:option});
  }

  function formatMoney(number, decPlaces, decSep, thouSep) {
    var decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    var j = (j = i.length) > 3 ? j % 3 : 0;

    return sign +
      (j ? i.substr(0, j) + thouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
      (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
  }

  function setChecked(id,callback){

    var itemId;
    $('#button_'+id).closest("table").eq(0).parent().parent().parent().parent().parent().children().find('.checked_indicator').each(function(){
      itemId = $(this).attr('id').replace('checked_indicator_');
      if($('#check_icon_'+itemId).css('opacity') == 0)
      {
        const raw_price = $('#raw_price_'+itemId).val();
        $('#calc_price_num_'+itemId).val(raw_price);
        $('#price_'+itemId).html(formatMoney(raw_price,2,",","."));
      }
    });
    if(callback != undefined && typeof callback == "function")
    {
      callback(id);
    }
  }

  function recalculatePrices(id)
  {
    var price = 0;
    var raw_price;
    var amount;
    const headPriceElement = $('#button_'+id).closest("table").eq(0).parent().parent().parent().parent().parent().find('.PriceRow').eq(0).find('span').eq(0);
    $('#button_'+id).closest("table").eq(0).parent().parent().parent().parent().parent().children().eq(1).find('.calc_price_numeric').each(function(){
      if($('#check_icon_'+$(this).attr('id').replace('calc_price_num_','')).css('opacity') > 0)
      {
        raw_price = $('#raw_price_'+$(this).attr('id').replace('calc_price_num_','')).val();
        amount = 1;
        if($('#stepper_'+$(this).attr('id').replace('calc_price_num_','')).length)
        {
          amount = $('#stepper_'+$(this).attr('id').replace('calc_price_num_','')).val();
        }
        price += parseFloat(raw_price)*parseFloat(amount);
      }
    });
    $(headPriceElement).html(formatMoney(price,2,",","."));
    calculateTotal();
  }

  function calculateTotal()
  {
    //console.log($('.finishPrice').find('span').eq(0));
    var total = 0;
    var raw_price;
    $('.headerPriceRow').each(function(){
      raw_price = parseFloat($(this).find('span').eq(0).html().replace('.','').replace(',','.'));
      //console.log({headerPriceRow:raw_price});
      total += raw_price;
    });
    total += parseFloat($('#machinePrice').val());
    $('.finishPrice').find('span').eq(0).html(formatMoney(total,2,",","."));
  }
}
