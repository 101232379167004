import { composeBundles, createCacheBundle } from 'redux-bundler';

import cache from '../common/cache';
import allProducts from './allProducts';
import appPathname from './appPathname';
import currentOrder from './currentOrder';
import currentProduct from './currentProduct';

import currentProductCategory from './currentProductCategory';
import currentProductOptions from './currentProductOptions';
import currentProducts from './currentProducts';
import currentTechnicalDetails from './currentTechnicalDetails';
import i18n from './i18n';
import productCategories from './productCategories';
import productConfigurator from './productConfigurator';
import productConfiguratorPreloader from './productConfiguratorPreloader';
import productFilter from './productFilter';
import productOrderManager from './productOrderManager';
import productTypes from './productTypes';
import queryParams from './queryParams';

import mayerApi from './resources/mayerApi';
import mayerBusinessAreas from './resources/mayerBusinessAreas';
import mayerGuiTranslations from './resources/mayerGuiTranslations';
import mayerMachineOptions from './resources/mayerMachineOptions';
import mayerMainMachines from './resources/mayerMainMachines';
import mayerProductTypes from './resources/mayerProductTypes';
import mayerTechnicalDetails from './resources/mayerTechnicalDetails';
import router from './router';
import currentProductPackages from './currentProductPackages';

export default composeBundles(
  currentTechnicalDetails,
  router,

  appPathname,
  queryParams,

  productCategories,
  currentProductCategory,
  allProducts,
  currentProducts,
  currentProduct,
  currentProductPackages,
  currentProductOptions,
  productConfigurator,
  productConfiguratorPreloader,
  productOrderManager,
  currentOrder,
  i18n,

  productTypes,
  productFilter,

  mayerApi,
  mayerBusinessAreas,
  mayerGuiTranslations,
  mayerMainMachines,
  mayerMachineOptions,
  mayerTechnicalDetails,
  mayerProductTypes,

  createCacheBundle({ logger: console.log.bind(console), cacheFn: cache.set.bind(cache) }),
);
