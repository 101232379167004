import React from 'react';

import styles from './ProductConstructorMaster.module.scss';
import ProductConstructorDisplay from './ProductConstructorDisplay';

export default function ProductConstructorMaster() {
  return (
    <div className={styles.wrapper}>
      <ProductConstructorDisplay />
    </div>
  );
}
