import React from 'react';
import { useEffect, useState } from 'react';
import { getNavHelper } from 'internal-nav-helper';
import { useConnect } from 'redux-bundler-hook';
import styles from './MayerPageWrapper.module.scss';

import { Navbar, Nav,  NavDropdown} from 'react-bootstrap';


import { AVAILABLE_LANGUAGES, useTranslate } from '../../bundles/i18n';


const HEADER_NAV = (t: string => string) => [
  { name: t('LNG_MAINPAGE_TOPMENU_ACTUAL'), path: 'https://mayer.de/pflanztechnik/news/' },
  { name: t('LNG_MAINPAGE_TOPMENU_PRODUCTS'), path: 'https://mayer.de/pflanztechnik/produkte/' },
  { name: t('LNG_MAINPAGE_TOPMENU_SERVICE'), path: 'https://mayer.de/pflanztechnik/service/' },
  { name: t('LNG_MAINPAGE_TOPMENU_REFERENCES'), path: 'https://mayer.de/pflanztechnik/referenzen/' },
  { name: t('LNG_MAINPAGE_TOPMENU_COMPANY'), path: 'https://mayer.de/pflanztechnik/unternehmen/' },
  { name: t('LNG_MAINPAGE_TOPMENU_CONTACT'), path: 'https://mayer.de/pflanztechnik/kontakt/' },
];

export default function Header() {
  const { t } = useTranslate();

  const { currentLanguage, languageSwitcherLinks, doUpdateUrl } = useConnect(
    'selectCurrentLanguage',
    'selectLanguageSwitcherLinks',
    'doUpdateUrl',
  );

 function  handleClick() {
  	this.setState({
  		open: !this.state.open
  	});
  }
  const [isSqueezed, setSqueezed] = useState(false);

  let navigation = HEADER_NAV(t);

  useEffect(() => {
    const handleScroll = () => setSqueezed(window.scrollY > 0);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  function renderList(list): React.Node {
    return list && list.length ? (
      <ul className={styles.main_nav}>
        {list.map(({ name, path }, i) => (
          <li key={name} className={styles.list_item}>
            <a className={styles.list_item__link} href={path}>
              {name.toUpperCase()}
            </a>
          </li>
        ))}
      </ul>
    ) : null;
  }

  function renderLanguageImg(lang): React.Node {
    return (
      <img
        className={styles.lang_image}
        src={require(`../../assets/img/languages/${lang}.png`)}
        title={lang}
        alt={lang}
      />
    );
  }

  return (


    <header className={styles.header}>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-106405730-1"></script>


      <div className={styles.info_header}>
        <div className={styles.header_content}>
          <div className={styles.header_left}>
              <div className={styles.phone}>
          <a href="https://mayer.de/" className={styles.connect}>
              <i className="fa fa-home" aria-hidden="true" />
              <span>{t('LNG_MAINPAGE_HEADER_MAYERGROUPE')}</span>
            </a>
            <a href="tel:+4973219594237" className={styles.connect}>
              <i className="fa fa-phone" aria-hidden="true" />
              <span>+49 7321 9594 237</span>
            </a>
            <a href="mailto:verkauf@mayer.de?subject=Planting-Systems" className={styles.connect}>
              <i className="fa fa-envelope-o" aria-hidden="true" />
              <span>verkauf@mayer.de</span>
            </a>
              </div>
          </div>

          <div className={styles.header_right}>
            <div className={styles.social_media}>
              <a href="https://www.facebook.com/mayergroupheidenheim/" target="_blank" className={styles.social}>
                <i className="fa fa-facebook" />
              </a>
              <a href="https://twitter.com/MayerGroupHDH" target="_blank" className={styles.social}>
                <i className="fa fa-twitter" />
              </a>
              <a href="https://www.linkedin.com/company/mayergroup/" target="_blank" className={styles.social}>
                <i className="fa fa-linkedin" />
              </a>
              <a href="https://www.xing.com/companies/mayergruppe" target="_blank" className={styles.social}>
                <i className="fa fa-xing" />
              </a>
              <a href="https://www.youtube.com/user/Topfmaschine" target="_blank" className={styles.social}>
                <i className="fa fa-youtube" />
              </a>
            </div>

            <div className={styles.dropdown}>
              <a className={styles.dropbtn}>
                {renderLanguageImg(currentLanguage)}
                {currentLanguage.toUpperCase()}
                <i className="fa fa-chevron-down" />
              </a>
              <div className={styles.dropdown_content}>
                {AVAILABLE_LANGUAGES.map(lang =>
                  lang == currentLanguage ? null : (
                    <a key={lang} href={languageSwitcherLinks[lang]} onClick={getNavHelper(doUpdateUrl)}>
                      {renderLanguageImg(lang)}
                      {lang.toUpperCase()}
                    </a>
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.main_menu}>
        <div className={styles.header_content}>

          <a href="/" >
            <img className={styles.logo} src={require('../../assets/img/logo.svg')} alt="logo" />
          </a>

          {renderList(navigation)}
        </div>

      </div>



    </header>


  );
}
