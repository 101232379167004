import React from 'react';
import { useConnect } from 'redux-bundler-hook';

import ProductConstructorCategory from './ProductConstructorCategory/ProductConstructorCate';
import ProductConstructorPackageButton from '../ProductConstructorPackageButton/ProductConstructorPackageButton';

import styless from '../ProductConstructorPackages/ProductConstructorPackage.module.scss';
import { useTranslate } from '../../../bundles/i18n';
import styles from './ProductConstructorOptions.module.scss';

export default function ProductConstructorOptions() {
    const { t } = useTranslate();
  const { configuratorCategories,currentProductPackages, doResetConfig,  doChangePackageConfig,
    configuratorComplete,
    currentOrderId,
    configuratorProduct,
    configuratorSelectedOptionIds,
    doStoreOrder,
    doUpdateOrder,
    doUpdateUrl,
    selectedConfiguratorOptions } = useConnect('selectCurrentProductPackages',
    'selectConfiguratorSelectedOptionIds',
    'doResetConfig',
    'doChangePackageConfig',
    'selectConfiguratorComplete',
    'selectConfiguratorProduct',
    'selectCurrentOrderId',
    'selectConfiguratorSelectedOptionIds',
    'doStoreOrder',
    'doUpdateOrder',
    'doUpdateUrl',
    'selectSelectedConfiguratorOptions',
    'selectConfiguratorCategories');


    const isCurrentPackage = options => eqSet(new Set(options), configuratorSelectedOptionIds);

    const isLongerDelivery = () => {
      return !Boolean(
        currentProductPackages &&
          currentProductPackages.filter(pack => eqSet(new Set(pack.options), configuratorSelectedOptionIds)).length,
      );
    };
  return (
    <div className={styles.wrapper}>
      <div>
        {currentProductPackages &&
          currentProductPackages.map((pack, index) => {
            return (


              <ProductConstructorPackageButton
                key={index}
                packOptions={pack.options}
                name={pack.name}
                isCurrent={isCurrentPackage(pack.options)}
              />


            );
          })}
      </div>


       <div>



           </div>
      <div>
        {isLongerDelivery() && Boolean(configuratorSelectedOptionIds.size) && (
          <button
            className={styless.reset_button}
            onClick={() => {
              if (window.confirm('Are you sure you wish to reset current config?')) doResetConfig();
            }}
          >

            { t('reset_configuration') }
          </button>
        )}
      </div>
      {configuratorCategories.map(category => (
        <ProductConstructorCategory key={category.id} category={category} />
      ))}
    </div>
  );
}
function eqSet(as, bs) {
  if (as.size !== bs.size) return false;
  for (var a of as) if (!bs.has(a)) return false;
  return true;
}
