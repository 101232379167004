import { createSelector } from 'redux-bundler';
import { localizedField } from './helpers/mayerApiLocalization';

const EMPTY = [];

export default {
  name: 'productTypes',

  selectProductTypes: createSelector(
    'selectMayerProductTypes',
    'selectCurrentLanguage',
    'selectCurrentProductCategoryId',
    (data, language, currentProductCategoryId) => {
      if (!data) {
        return EMPTY;
      }

      return data.filter(category => Boolean(category.showFor.includes(currentProductCategoryId))).map(item => ({
        id: item['CategoryID'],
        name: localizedField(item, 'CategoryName', language),
        subTypes: (item['subCategory'] || []).map(subType => ({
          id: subType['CategoryID'],
          name: localizedField(subType, 'CategoryName', language),
        })),
      }));
    },
  ),
};
