import React from 'react';
import cn from 'classnames';

import styles from './ConstructorButton.module.scss';

export default function ConstructorButton({ title, activated, onClick, className }) {
  return (
    <button className={cn(styles.button, className, { [styles.buttonActivated]: activated })} onClick={onClick}>
      {title}
    </button>
  );
}
