import { createSelector } from 'redux-bundler';
import { ALL_PRODUCT_CATEGORY } from './productCategories';

const BLANK = [];

export default {
  name: 'currentProducts',

  selectCurrentCategoryProducts: createSelector(
    'selectAllProducts',
    'selectCurrentProductCategoryId',
    (products, categoryId) => {
      //console.log({products:products, categoryId:categoryId});
      let result = [];

      if (categoryId === ALL_PRODUCT_CATEGORY) {
        result = products;
      } else if (categoryId) {
        result = products.filter(p => p.categoryId === categoryId);
      }

      if (!result.length) {
        return BLANK;
      }
      //console.log({selectCurrentCategoryProducts:result});
      return result;
    },
  ),

  selectCurrentProducts: createSelector('selectCurrentCategoryProducts', 'selectProductFilter', 'selectProductTypes', (products, filter, productTypes) => {
    //console.log({selectCurrentProducts:products});
    if (!products.length) {
      // don't change reference type so selectCurrentProductsReady works
      return products;
    }
    //console.log({products:products, filter:filter, productTypes:productTypes});

    return products.filter(p => {
      if (filter.productType !== null) {
        const productType = productTypes.filter(pt => pt.id === filter.productType.mainType)[0];
        if(productType.subType[0] === false && productType[1] === false) // no subtype filter set
        {
           return false;
        }
        else if(productType.subType[0] !== false && productType[1] === false) // subtype 11 filter set
        {
           if(p.productType.subType != 11)
           {
              return false;
           }
        }
        else if(productType.subType[0] === false && productType[1] !== false) // subtype 12 filter set
        {
           if(p.productType.subType != 12)
           {
              return false;
           }
        }
        else // subtype 11 & 12 filter set
        {
           // nothing to do, this is true
        }
        /*if (productType && productType.subTypes && productType.subTypes.length) {
          if (filter.productType.subType && filter.productType.subType !== null) {
            if (p.productTypeId !== filter.productType.subType) {
              return false;
            }
          } else {
            if (filter.productType.subType === null) {
              if (!(p.productTypeId === filter.productType.mainType || productType.subTypes.filter(st => st.id === p.productTypeId).length)) {
                return false;
              }
            }
          }
        } else {
          if (p.productTypeId !== filter.productType.mainType) {
            return false;
          }
        }*/
      }

      /*if (filter.minPerformance !== null && p.performance > filter.minPerformance) {
        return false;
      }*/

      /*if (
        filter.potSizeRange !== null &&
        (p.minPotSize > filter.potSizeRange[0] || p.maxPotSize < filter.potSizeRange[1])
      ) {
        return false;
      }*/

      return true;
    });
  }),

  selectCurrentProductsReady: createSelector(
    'selectCurrentProducts',
    'selectProductTypes',
    (products, productTypes) => products !== BLANK && Boolean(productTypes.length),
  ),
};
