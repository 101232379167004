import React, { forwardRef } from 'react';
import cn from 'classnames';

import { useLinks } from '../../bundles/router';

import styles from './PageContentHeaderTab.module.scss';

export default forwardRef(({ title, imageUrl, className, route, active, outlined }, ref) => {
  const { navHelper } = useLinks();

  return (
    <div ref={ref} className={cn(styles.wrapper, className)}>
      <a
        href={route}
        onClick={navHelper}
        className={cn(styles.tab, { [styles.active]: active, [styles.outlined]: outlined })}
      >
        <img src={imageUrl} alt={title} />
        <span>{title}</span>
      </a>
    </div>
  );
});
